export enum WindowType {
  ADD_MEMBER,
  HISTORY,
  RIGHTS,
  MFA,
  PASSWORD,
  WARNING,
  FORM,
  IMAGE,
  DownloadCertificate,
}

export enum DrawerType {
  CONNECT_ENTITY,
  ADD_TAX_RETURN,
  ADD_TAX_SUBMISSION,
  ADD_MEMBER,
  LOAD_FROM_OTHER_ENTITY,
  HISTORY,
  RIGHTS,
  EDIT,
  CHANGE_OWNER,
  EDIT_FRIENDLY_NAME,
  INVITE_USER,
  NOT_APPROVED_FORM,
  ACTIVITY_LOGS,
  VIES_LOGS,
  MFA,
  CONNECT_CIN7,
}

export enum VatTaxReportType {
  VAT_RETURN = 'VAT_RETURN',
  RECAP = 'RECAP',
  CASH_ACCOUNTING = 'CASH_ACCOUNTING',
}

export enum DataLogStatus {
  SAVED = 'Saved',
  LOCKED = 'Locked',
  SUBMITTED = 'Submitted',
  CANCELLED = 'Cancelled',
  AWAITING_APPROVAL = 'Awaiting approval',
  INTERNAL_APPROVED = 'Internal Approved',
  APPROVED = 'Approved',
  AWAITING_CLIENT_APPROVAL = 'Awaiting Client Approval',
  CLIENT_APPROVED = 'Client Approved',
  NOT_SAVED = 'Not Saved',
}

export enum DataLogActions {
  REVERT_DATA = 'Revert data',
  SAVE_VAT_RETURN = 'Save VAT return',
  CANCEL_RETURN = 'Cancel return',
  LOCK_AS_APPROVED = 'Lock as approved',
  SET_AWAITING_APPROVAL = 'Set as awaiting approval',
  SET_INTERNAL_APPROVED = 'Set as internal approved',
  SET_NOT_APPROVED = 'Set not approved',
  SEND_FOR_CLIENT_APPROVAL = 'Send for client approval',
  SET_CLIENT_APPROVED = 'Set as client approved',
  MARK_AS_SUBMITTED = 'Mark as submitted with authorities',
}

export enum ViesStatus {
  VALID = 'Valid',
  INVALID = 'Invalid',
  ERROR = 'Error',
  EMPTY = 'Missing',
}

export enum TaxReportCountry {
  MALTA = 'Malta',
  CYPRUS = 'Cyprus',
  UAE = 'UAE',
  IRELAND_DETAILED = 'IrelandDetailed',
  IRELAND_STANDARD = 'IrelandStandard',
  IRELAND_RTD = 'IrelandRTD',
  ISLE_OF_MAN = 'IsleOfMan',
}

export enum ActivityRoles {
  PREPARER = 'preparer',
  REVIEWER = 'reviewer',
  CLIENT = 'client',
  REMOVE_REVIEWER = 'remove-reviewer',
  REMOVE_CLIENT = 'remove-client',
}

export enum ActivityType {
  SAVED = 'Saved',
  LOCKED = 'Locked',
  SUBMITTED = 'Submitted',
  NOTE = 'Note',
  REFRESHED = 'Refreshed',
  CANCELLED = 'Cancelled',
  PREPARER = 'Preparer',
  REVIEWER = 'Reviewer',
  CLIENT = 'Client',
  AWAITING_APPROVAL = 'Awaiting Approval',
  INTERNAL_APPROVED = 'Internal Approval',
  INTERNAL_NOT_APPROVED = 'Internal Not Approved',
  AWAITING_CLIENT_APPROVAL = 'Awaiting Client Approval',
  CLIENT_NOT_APPROVED = 'Client Not Approved',
  CLIENT_APPROVED = 'Client Approved',
}

export enum MenuSize {
  NARROW,
  WIDE,
}

export enum Pages {
  DASHBOARD = 'Dashboard',
  ENTITIES = 'Entities',
  REPORTING = 'Reporting',
  BILLING = 'Billing',
  VIES = 'VAT Number Checker',
  USERS = 'Users',
  SUPPORT = 'Support',
  WHATS_NEW = "What's New",
  PROFILE = 'Profile',
  RETURN = 'Return',
  SUBMISSION = 'Submission',
  REVIEW = 'Review Submission',
}

export enum dashboardStatistic {
  taxReturns = 'tax-returns',
  dueReturns = 'due-returns',
  expiredReturns = 'expired-returns',
  awaitingClient = 'awaiting-client',
}

export enum VIESContactStatistic {
  Customers = 'customers',
  Suppliers = 'suppliers',
  ContactsErrors = 'contacts-errors',
  StatusMissing = 'status-missing',
}

export enum CurrentModal {
  NONE,
  HISTORY,
  RIGHTS,
  EDIT,
}

export enum SubmissionUpdateField {
  STATUS,
  PREPARER,
  REVIEWER,
  CLIENT,
  REMOVE_CLIENT,
  REMOVE_REVIEWER,
}

export enum ContactType {
  SUPPLIER = 'Supplier',
  CUSTOMER = 'Customer',
  BOTH = 'Both',
}

export enum ViesTriggerType {
  WEBHOOK = 'Auto-log from',
  CONTACT_REFRESH = 'Manual refresh',
  //ALL_CONTACTS_REFRESH = 'All contacts refresh',
  CONTACT_CREATE = 'New record',
  SINGLE_CONTACT_VALIDATION = 'Manual refresh',
  ALL_CONTACTS_VALIDATION = 'Manual refresh',
  TAX_GENERATION = 'System generated refresh',
}
