import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useHistory, useParams } from 'react-router';
import { saveMappingRequest } from 'store/returns/actions';
import {
  getReturnData,
  getReturnLoading,
  getReturnPreLoading,
  getReturnTaxRatesLoading,
} from 'store/returns/selectors';
import { MappingForm } from 'components/MappingForm';
import { useState } from 'react';
import { MappingData } from 'store/returns/models';
import {
  getOrgMappings,
  getOrgOrganisations,
} from 'store/organisation/selectors';
import moment from 'moment';
import { calculateNextPeriod } from 'utils/calculate-next-period';
import { Form, Spin } from 'antd';
import { Section, TaxRate } from 'store/returns/models/returnData';
import { MappingTaxRate } from 'store/returns/models/mappingTaxRate';
import {
  turnDrawerOn,
  turnMessageOn,
  turnModalOff,
  turnModalOn,
  updateMappingData,
} from 'store/app/actions';
import { MessageStates } from 'containers/MessageBox';
import { DrawerData, MessageData, ModalData } from 'store/app/types';
import { useTranslation } from 'react-i18next';
import { getMappingData } from 'store/app/selectors';
import { DrawerType, WindowType } from 'store/app/enums';
import { ChooseReturn } from 'components/MappingForm/choose-return';
import { push } from 'connected-react-router';
import routes from 'routes';
import { ChooseTemplate } from 'components/MappingForm/choose-template';
import { getCommonCountries } from 'store/common/selectors';
import { ConfirmBoxContainer } from 'containers/ConfirmBox';
import * as _ from 'lodash';

export interface StepOneData {
  entity?: string;
  country?: string;
  typeName?: string;
  firstPeriod?: string;
  firstDueDate?: string;
}

export type TaxRateOption = {
  label: string;
  value: string;
};

export interface SelectedRate {
  value: string;
  location: string[];
  cashAccounting: boolean;
  reverseCharge: number | null;
}

export interface TemplateMapping {
  mappingId: string;
  organisationName: string;
  createdDate: string;
  country?: string;
}

export interface ReverseCharge {
  rateId: string;
  used: boolean;
  amount: number | null;
}

export const AddReturnPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const parameters: any = useParams();
  const conId = parameters.connectionId;
  const mappingId = parameters.mappingId;
  const mappings = useSelector(getOrgMappings);
  const organisations = useSelector(getOrgOrganisations);
  const preLoading = useSelector(getReturnPreLoading);
  const taxRatesLoading = useSelector(getReturnTaxRatesLoading);
  const loading = useSelector(getReturnLoading);
  const returnData = useSelector(getReturnData);
  const mappingData = useSelector(getMappingData);
  const countries = useSelector(getCommonCountries);
  const [form2] = Form.useForm();
  const [stepOneData, setStepOneData] = useState<StepOneData>({});
  const editMode: boolean = !!mappingId;
  const [selectedReturnType, setSelectedReturnType] = useState<string>();
  const [taxRateOptions, setTaxRateOptions] = useState<TaxRateOption[]>();
  const [outOfScopeOptions, setOutOfScopeOptions] = useState<TaxRateOption[]>();
  const [sections, setSections] = useState<Section[]>();
  const [cashAccounting, setCashAccounting] = useState<boolean>(false);
  const [outOfScopeCode, setOutOfScopeCode] = useState<string>('OOS');
  const [selectedRates, setSelectedRates] = useState<SelectedRate[]>([]);
  const [selectedRatesCopy, setSelectedRatesCopy] = useState<SelectedRate[]>(
    []
  );
  const [oldSelectedRates, setOldSelectedRates] = useState<SelectedRate[]>([]);

  const history = useHistory();
  const [dataSaved, setDataSaved] = React.useState<boolean>(false);
  const [sectionTouched, setSectionTouched] = React.useState<boolean>(false);
  const unblockRef = React.useRef();

  //const [current, setCurrent] = useState(0);
  const [templateMappings, setTemplateMappings] = useState<TemplateMapping[]>(
    []
  );
  const [reverseCharges, setReverseCharges] = useState<ReverseCharge[]>([]);

  const editInitialData = () => {
    if (conId) {
      const drawerData: DrawerData = {
        title: 'Add a Tax Return',
        type: DrawerType.ADD_TAX_RETURN,
        component: (
          <ChooseReturn editMode={true} setSectionTouched={setSectionTouched} />
        ),
      };
      dispatch(turnDrawerOn(drawerData));
    }
  };

  const setMappingData = (data: MappingData) => {
    dispatch(updateMappingData(data));
  };

  const updateSelectedRates = (mappedData: SelectedRate[]) => {
    setSelectedRates(mappedData);
    setSelectedRatesCopy([...mappedData]);
  };

  const saveMappingData = (mData: MappingData) => {
    dispatch(saveMappingRequest(conId, mData));
  };

  const openTemplateWindow = () => {
    const drawerData: DrawerData = {
      title: 'Load from another Entity',
      type: DrawerType.LOAD_FROM_OTHER_ENTITY,
      component: (
        <ChooseTemplate
          templateMappings={templateMappings}
          loadExistingMapping={loadExistingMapping}
        />
      ),
    };
    dispatch(turnDrawerOn(drawerData));
  };

  const setOptions = (
    trOptions: TaxRateOption[],
    oosOptions: TaxRateOption[]
  ) => {
    setTaxRateOptions(trOptions);
    setOutOfScopeOptions(oosOptions);
  };

  const prepareTaxRateOptions = () => {
    const opts: TaxRateOption[] = [];
    returnData?.taxRates?.map((tr) =>
      opts.push({
        value: tr.id,
        label: `${tr.name} [${tr.taxRate.toString()}%]`,
      })
    );
    return opts;
  };

  const sortSelectionsByReturnType = (returnType: string) => {
    setSelectedReturnType(returnType);
    const hasCA = returnData?.returnTypesEdit?.find(
      (rt) => rt.id === returnType
    )?.cashAccountingEnabled;

    hasCA ? setCashAccounting(true) : setCashAccounting(false);

    const sec = returnData?.sections?.filter((s) => {
      return (
        s.returnTypeId === returnType ||
        (hasCA && s.name === s.subsections[0].code)
      );
    });

    sec?.sort((a, b) => a.sortOrder - b.sortOrder);
    sec && setSections(sec);
    const ooc = sec?.find((s) => s.subsections[0].outOfScope)?.subsections[0]
      .code;
    ooc && setOutOfScopeCode(ooc);
  };

  const addUnmappedToOutOfScope = () => {
    if (
      returnData?.taxRates &&
      selectedRates.length === returnData?.taxRates?.length
    ) {
      const message: MessageData = {
        title: t('common.attention') + '!',
        description: t('returns.mapping.all-rates-mapped'),
        type: MessageStates.INFO,
      };
      dispatch(turnMessageOn(message));
    } else {
      const md: SelectedRate[] = [];
      const oosValue: string[] = [];
      if (returnData?.taxRates) {
        returnData?.taxRates.map((rt) => {
          const nIndex = selectedRates.findIndex((sr) => sr.value === rt.id);
          let mdRow: SelectedRate;
          if (nIndex === -1) {
            mdRow = {
              value: rt.id,
              location: [outOfScopeCode],
              cashAccounting: false,
              reverseCharge: null,
            };
            oosValue.push(rt.id);
          } else {
            mdRow = { ...selectedRates[nIndex] };
            if (mdRow.location[0] === outOfScopeCode) oosValue.push(rt.id);
          }

          md.push(mdRow);
        });

        updateSelectedRates(md);
        handleTaxRangeChange(oosValue, true, '');
        form2.setFieldsValue({
          ['outOfScope']: oosValue,
        });
      }
    }
  };

  const handleCashAccounting = (rateId: string) => {
    const tempSelRates: SelectedRate[] = [...selectedRatesCopy];
    const nIndex = tempSelRates.findIndex((tsr) => tsr.value === rateId);
    if (nIndex > -1) {
      tempSelRates[nIndex] = {
        ...tempSelRates[nIndex],
        cashAccounting: !tempSelRates[nIndex]?.cashAccounting,
      };
      setSelectedRatesCopy(tempSelRates);
    }
  };

  const handleReverseCharge = (rateId: string, val: string | null) => {
    const value = val !== null ? parseFloat(val) : null;
    const tempReverseCharges: ReverseCharge[] = [...reverseCharges];
    const nIndex = tempReverseCharges.findIndex((trc) => trc.rateId === rateId);
    if (nIndex > -1) {
      const used =
        value !== null && value > 0
          ? tempReverseCharges[nIndex].used
          : !tempReverseCharges[nIndex].used;
      const amount =
        value !== null && value > 0 ? value : tempReverseCharges[nIndex].amount;

      tempReverseCharges[nIndex] = {
        ...tempReverseCharges[nIndex],
        used,
        amount,
      };
      setReverseCharges(tempReverseCharges);
    }
  };

  const fillCashAccounting = (value: boolean) => {
    const tempSelRates: SelectedRate[] = [];

    selectedRatesCopy.forEach((sr) => {
      tempSelRates.push({ ...sr, cashAccounting: value });
    });
    setSelectedRatesCopy(tempSelRates);
  };

  const resetReverseCharge = () => {
    const tempReverseCharges: ReverseCharge[] = [];

    reverseCharges.forEach((rc) => {
      tempReverseCharges.push({ ...rc, amount: null, used: false });
    });
    setReverseCharges(tempReverseCharges);
  };

  const saveCashAccounting = () => {
    setSectionTouched(true);
    setSelectedRates(selectedRatesCopy);
  };

  const saveReverseCharges = () => {
    if (
      reverseCharges.find(
        (rc) => (rc.amount === 0 || rc.amount === null) && rc.used
      )
    ) {
      const message: MessageData = {
        title: t('common.problem-saving') + '!',
        description:
          'The Reverse Charge cannot be saved.  Please ensure that all selected taxes have been assigned a % tax rate.',
        type: MessageStates.WARNING,
      };
      dispatch(turnMessageOn(message));
      return false;
    } else {
      setSectionTouched(true);
      const tempSelectedRates = [...selectedRates];
      reverseCharges.forEach((rc) => {
        const nIndex = tempSelectedRates.findIndex(
          (tsr) => tsr.value === rc.rateId
        );
        if (nIndex > -1)
          if (rc.used) {
            tempSelectedRates[nIndex].reverseCharge = rc.amount;
          } else tempSelectedRates[nIndex].reverseCharge = null;
      });
      setSelectedRates(tempSelectedRates);
      return true;
    }
  };

  const loadCashAccounting = () => {
    setSelectedRatesCopy([...selectedRates]);
  };

  const loadReverseCharge = () => {
    const revCharges: ReverseCharge[] = [];
    selectedRates.forEach((sr) => {
      const rateId = sr.value;
      const used = sr.reverseCharge !== null && sr.reverseCharge !== 0;
      const amount = sr.reverseCharge;
      const revCharge: ReverseCharge = { rateId, used, amount };
      revCharges.push(revCharge);
    });
    setReverseCharges(revCharges);
  };

  const handleTaxRangeChange = (
    rateIds: string[],
    outOfScope: boolean,
    code: string
  ) => {
    setSectionTouched(true);
    const opts: TaxRateOption[] = prepareTaxRateOptions();
    if (outOfScope) {
      const tempSelRates: SelectedRate[] = [...selectedRates];
      setOldSelectedRates(tempSelRates);
      rateIds.map((r) => {
        const rateIndex = opts.findIndex((o) => o.value === r);
        rateIndex > -1 && opts.splice(rateIndex, 1);
        const srIndex = tempSelRates.findIndex((tsr) => tsr.value === r);
        if (srIndex === -1)
          tempSelRates.push({
            value: r,
            location: [outOfScopeCode],
            cashAccounting: false,
            reverseCharge: null,
          });
        else
          tempSelRates.splice(srIndex, 1, {
            value: r,
            location: [outOfScopeCode],
            cashAccounting: false,
            reverseCharge: null,
          });
      });
      const tempSR = [...tempSelRates];
      tempSR.map((t, idx) => {
        const locIndex = t.location.findIndex((l) => l === outOfScopeCode);
        if (locIndex > -1 && rateIds.findIndex((ri) => t.value === ri) === -1) {
          tempSelRates[idx].location = [];
        }
      });
      const tmpRates = tempSelRates.filter((tsr) => tsr.location.length !== 0);
      setSelectedRates(tmpRates);
      setSelectedRatesCopy([...tmpRates]);
      setTaxRateOptions(opts);
    } else {
      const tempSelRates: SelectedRate[] = [...selectedRates];
      setOldSelectedRates(tempSelRates);
      rateIds.map((ri) => {
        const selIndex = selectedRates.findIndex((sr) => sr.value === ri);
        if (selIndex === -1)
          tempSelRates.push({
            value: ri,
            location: [code],
            cashAccounting: false,
            reverseCharge: null,
          });
        else {
          const codeIndex = selectedRates[selIndex].location.findIndex(
            (l) => l === code
          );
          if (codeIndex === -1) selectedRates[selIndex].location.push(code);
        }
      });
      const tempSR = [...tempSelRates];
      tempSR.map((t, idx) => {
        const locIndex = t.location.findIndex((l) => l === code);
        if (locIndex > -1 && rateIds.findIndex((ri) => t.value === ri) === -1) {
          tempSelRates[idx].location.splice(locIndex, 1);
        }
      });
      const finalSelRates = tempSelRates.filter(
        (tsr) => tsr.location.length > 0
      );

      finalSelRates.map((fsr) => {
        const rateIndex = opts.findIndex((o) => o.value === fsr.value);
        if (rateIndex > -1 && fsr.location[0] !== outOfScopeCode)
          opts.splice(rateIndex, 1);
      });
      setSelectedRates(finalSelRates);
      setSelectedRatesCopy([...finalSelRates]);
      setOutOfScopeOptions(opts);
    }
  };

  const loadExistingMapping = (mapId: string, isTemplate = false) => {
    const savingMapId = isTemplate ? mappingId : mapId;
    const selMap = mappings?.find((m) => m.mappingId === mapId);
    const savingPeriodFromDate = isTemplate
      ? mappingData?.periodFromDate
      : selMap?.periodFromDate;
    const savingReturnDueDate = isTemplate
      ? mappingData?.returnDueDate
      : selMap?.returnDueDate;
    const savingReturnFrequency = isTemplate
      ? mappingData?.returnFrequency
      : selMap?.returnFrequency;
    let jsonMapping;
    if (selMap?.jsonMapping) {
      try {
        const parsedMapping = JSON.parse(selMap.jsonMapping);

        jsonMapping = parsedMapping?.mapping;
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    }
    if (
      returnData?.taxRates &&
      returnData.taxRates.length < jsonMapping.length
    ) {
      const toDelete = jsonMapping.filter(
        (jm) =>
          returnData.taxRates?.findIndex((tr) => tr.taxType === jm.taxType) ===
          -1
      );
      toDelete.map((td) => {
        jsonMapping.splice(
          jsonMapping.findIndex((jm) => jm.taxType === td.taxType),
          1
        );
      });
    }
    const mapData = {
      mappingId: savingMapId,
      templateMappingId: mapId,
      typeId: returnData?.returnTypesEdit?.find(
        (rt) => rt.id === selMap?.returnTypeId
      )?.id,
      countryId: returnData?.returnTypesEdit?.find(
        (rt) => rt.id === selMap?.returnTypeId
      )?.countryId,
      mappedCashAccounting: selMap?.cashAccounting,
      periodFromDate: moment(savingPeriodFromDate).format('YYYY-MM-DD'),
      returnDueDate: moment(savingReturnDueDate).format('YYYY-MM-DD'),
      returnFrequency: savingReturnFrequency,
      jsonMapping,
    };
    setMappingData(mapData);
  };

  const resetForm = () => {
    const opts: TaxRateOption[] = prepareTaxRateOptions();
    const jsonMapping: MappingTaxRate[] = [];
    const mapData: MappingData = { ...mappingData, jsonMapping };
    updateSelectedRates([]);
    setMappingData(mapData);
    form2.resetFields();
    setTaxRateOptions(opts);
    setOutOfScopeOptions(opts);
  };

  React.useEffect(() => {
    if (!preLoading) {
      const retTypes = returnData?.returnTypesEdit;
      if (editMode && retTypes && retTypes.length > 0) {
        loadExistingMapping(mappingId);
      }
    }
  }, [preLoading, mappings]);

  React.useEffect(() => {
    if (mappingData && mappingData.mappingId) {
      setSelectedReturnType(mappingData.typeId);
    }
    let entity: any = '';
    let country: any = '';
    let typeName: any = '';
    let firstPeriod: any = '';
    let firstDueDate = '';
    if (mappingData) {
      if (mappingData.typeId) {
        const curType = returnData?.returnTypesEdit?.find(
          (rt) => rt.id === mappingData?.typeId
        );
        typeName = curType?.name;
        country = curType?.countryName;
      }
      if (mappingData.periodFromDate && mappingData.returnFrequency) {
        firstPeriod = calculateNextPeriod(
          mappingData.periodFromDate,
          mappingData.returnFrequency
        )[0];
        if (mappingData.returnDueDate) firstDueDate = mappingData.returnDueDate;
      }
      if (organisations && conId) {
        entity = organisations.find(
          (o) => o.connectionId === conId
        )?.friendlyName;
      }

      setStepOneData({ entity, country, typeName, firstPeriod, firstDueDate });
    }
  }, [mappingData]);

  React.useEffect(() => {
    if (returnData?.taxRates) {
      if (!editMode) {
        const opts: TaxRateOption[] = prepareTaxRateOptions();
        setTaxRateOptions(opts);
        setOutOfScopeOptions(opts);
      }
      mappingData?.typeId && sortSelectionsByReturnType(mappingData?.typeId);
    }
  }, [returnData]);

  React.useEffect(() => {
    if (!loading && !returnData && !mappingData?.typeId)
      dispatch(push(routes.main));
  }, []);

  const loadTemplateMappings = () => {
    if (mappings) {
      const otherMaps = mappings.filter((m) => {
        return (
          m.mappingId !== mappingId && m.returnTypeId === selectedReturnType
        );
      });

      const tmplMappings: TemplateMapping[] = [];
      otherMaps.map((om) => {
        const orName = organisations?.find(
          (o) => o.uuid === om.organisationId
        )?.friendlyName;
        const createdDate = moment(om.createdDate).format('DD/MM/YYYY');
        const organisationName = orName ? orName : '';
        const mappingId = om.mappingId;
        const countryIso = organisations?.find(
          (o) => o.uuid === om.organisationId
        )?.country;
        const country = countries.find((c) => c.iso === countryIso)?.name;
        tmplMappings.push({
          createdDate,
          organisationName,
          mappingId,
          country,
        });
      });

      setTemplateMappings(tmplMappings);
    }
  };

  const goBackWarningWindow = (data: any, location: any) => {
    const navigate = () => {
      if (unblockRef) {
        unblockRef.current();
      }
      dispatch(turnModalOff());
      history.push(location.pathname);
    };

    if (
      !dataSaved &&
      sectionTouched &&
      !location.pathname.includes('/editMapping/')
    ) {
      const modalData: ModalData = {
        type: WindowType.WARNING,
        title: 'Your mapping is not saved, do you want to save this mapping?',
        component: (
          <ConfirmBoxContainer
            callback={navigate}
            callback2={onSubmit}
            showCancelBtn={false}
            param2={data}
            okText="Continue without Saving"
            okText2="Save Mapping"
            description="Are you sure you want to go back? These changes are not saved. Once you click ‘Continue’, you will lose current changes."
          />
        ),
      };

      dispatch(turnModalOn(modalData));
    } else history.goBack();
  };

  React.useEffect(() => {
    unblockRef.current = history.block((location: any, _action: any) => {
      if (!dataSaved && sectionTouched) {
        goBackWarningWindow(oldSelectedRates, location);
        return false;
      }
      return true;
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [oldSelectedRates, dataSaved, selectedRates]);

  const onSubmit = () => {
    setSectionTouched(false);
    let passed = false;
    if (
      !returnData?.taxRates ||
      selectedRates.length !== returnData.taxRates.length
    ) {
      const message: MessageData = {
        title: t('common.problem-saving') + '!',
        description: t('returns.mapping.rates-must-be-mapped'),
        type: MessageStates.WARNING,
      };
      dispatch(turnMessageOn(message));
    } else {
      const jsonMapping: MappingTaxRate[] = [];
      let mappedCashAccounting = false;
      let mappedReverseCharges = false;

      selectedRates.map((sr) => {
        const rate: TaxRate | undefined = returnData?.taxRates?.find(
          (tr) => tr.id === sr.value
        );
        if (rate) {
          let codes: string | undefined = '';

          if (sr.cashAccounting) {
            mappedCashAccounting = true;
            codes =
              ',' +
              returnData?.sections?.find(
                (s) => s.name === s.subsections[0].code
              )?.subsections[0].code;
          }
          sr.location.map((l) => {
            codes = `${codes},${l}`;
          });
          const reverseCharge = sr.reverseCharge;
          if (reverseCharge !== null && reverseCharge > 0)
            mappedReverseCharges = true;
          const taxCodeName = rate?.name;
          const taxRate = rate?.taxRate.toFixed(2);
          const taxType = rate?.taxType;
          codes = codes?.substring(1);
          jsonMapping.push({
            taxCodeName,
            taxRate,
            taxType,
            codes,
            reverseCharge:
              reverseCharge !== null && reverseCharge > 0
                ? reverseCharge.toFixed(2)
                : null,
          });
        }
      });

      const md: MappingData = {
        ...mappingData,
        mappedCashAccounting,
        mappedReverseCharges,
        jsonMapping,
      };

      passed = true;
      setDataSaved(true);
      saveMappingData(md);
    }

    return passed;
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Add a return | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for adding a return" />
      </Helmet>
      <Spin spinning={taxRatesLoading || loading || !sections} size="large">
        {!sections ||
          (sections.length != 0 && (
            <MappingForm
              preLoading={preLoading}
              taxRatesLoading={taxRatesLoading}
              loading={loading}
              returnData={returnData}
              mappingData={mappingData}
              stepOneData={stepOneData}
              form2={form2}
              editMode={editMode}
              selectedRates={selectedRates}
              selectedRatesCopy={selectedRatesCopy}
              reverseCharges={reverseCharges}
              outOfScopeCode={outOfScopeCode}
              cashAccounting={cashAccounting}
              handleCashAccounting={handleCashAccounting}
              handleReverseCharge={handleReverseCharge}
              addUnmappedToOutOfScope={addUnmappedToOutOfScope}
              updateSelectedRates={updateSelectedRates}
              sections={sections}
              taxRateOptions={taxRateOptions}
              outOfScopeOptions={outOfScopeOptions}
              handleTaxRangeChange={handleTaxRangeChange}
              setOptions={setOptions}
              prepareTaxRateOptions={prepareTaxRateOptions}
              hasTemplates={templateMappings.length > 0}
              loadTemplateMappings={loadTemplateMappings}
              openTemplateWindow={openTemplateWindow}
              resetForm={resetForm}
              editInitialData={editInitialData}
              saveCashAccounting={saveCashAccounting}
              saveReverseCharges={saveReverseCharges}
              resetReverseCharge={resetReverseCharge}
              fillCashAccounting={fillCashAccounting}
              loadCashAccounting={loadCashAccounting}
              loadReverseCharge={loadReverseCharge}
              onSubmit={onSubmit}
            />
          ))}
      </Spin>
    </React.Fragment>
  );
};
