export enum MessageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum MessageStatus {
  OK = 'OK',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum Provider {
  XERO = 'xero',
  QUICKBOOKS = 'quickbooks',
  GOOGLE = 'google',
  CIN7 = 'dear',
  MICROSOFT = 'microsoft',
}

export enum ErrorCode {
  EMPTY_CODE,
  CONNECTION_ERROR,
  USER_ERROR,
  ORGANISATION_ERROR,
  KEY_ERROR,
  PROMO_ERROR,
  NO_ERROR,
}

export enum ReturnFrequency {
  BI_YEARLY = '2 Years',
  FORNIGHTLY = 'Fortnightly',
  MONTHLY = 'Monthly',
  TWO_MONTHS = '2 Months',
  SIX_MONTHS = '6 Months',
  QUARTERLY = 'Quarterly',
  WEEKLY = 'Weekly',
  YEARLY = 'Yearly',
}

export enum AccountType {
  OWNER = 'Owner',
  STAFF = 'User',
  EXSTAFF = 'Ex-staff',
  SUPPORT = 'Support',
  ADMIN = 'Admin',
  DDEXPERT = 'DD-Expert',
  UNSPECIFIED = 'Unspecified',
  CLIENT = 'Client',
}

export enum PermissionType {
  ORGANISATIONS = 'organisations',
  USERS = 'users',
}

export enum TablePermission {
  READ = 'READ',
  WRITE = 'WRITE',
  ALL = 'ALL',
  NONE = 'NONE',
  OWNER_NONE = 'OWNER_NONE',
}

export enum DateOptions {
  ALL = 'All',
  TODAY = 'Today',
  THIS_MONTH = 'This month',
  NEXT_MONTH = 'Next month',
  THIS_WEEK = 'This week',
  NEXT_WEEK = 'Next week',
  THIS_QUARTER = 'This quarter',
  NEXT_QUARTER = 'Next quarter',
}

export enum JournalType {
  SUMMARISED = 'Summarised ',
  DETAILED = 'Detailed',
}
