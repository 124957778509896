import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ImgWithXeroSignIn from 'assets/images/xero_icon.png';
import ImgWithGoogleSignIn from 'assets/images/google-icon.png';
import ImgWithMicrosoftSignIn from 'assets/images/microsoft-icon.png';
import routes from 'routes';
import { UserDetails } from 'store/signup/models';
import { Provider } from 'store/api/enums';
import { MfaResponse } from 'store/auth/models';
import ReCAPTCHA from 'react-google-recaptcha';
import { ScopeButton, SSOLoginButton } from 'components/Custom/buttons';
import {
  BoldSpanMidSize,
  BoldSpanLargeSize,
  SSOLoginSpan,
} from 'components/Custom/spans';
import { CenterLogo } from 'components/Custom/logo';
import { FormWrapper } from 'components/Custom/wrappers';
import {
  FormInput,
  FormPasswordInput,
  OTPInput,
} from 'components/Custom/inputs';
import { SSOImg } from 'components/Custom/images';
import Logo from 'assets/images/generateTAX-logo.svg';
import { navigateToExternalPage } from 'utils/validation/common';

interface LoginFormProps {
  onLogin: (
    email: string,
    password: string,
    rememberMe: boolean,
    gCaptchaResponse: string,
    mfaCode: string | null
  ) => void;
  loading?: boolean;
  savedLoginParams: UserDetails | null;
  onProviderLogin: (provider: Provider) => void;
  mfaResponse?: MfaResponse;
}

interface LoginFormValues {
  email: string;
  password: string;
}

interface LoginFormValuesMfa {
  email: string;
  password: string;
  mfaCode: string;
}

export const LoginForm: React.FC<LoginFormProps> = ({
  onLogin,
  loading,
  savedLoginParams,
  onProviderLogin,
  mfaResponse,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState({
    email: '',
    password: '',
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (savedLoginParams !== null) {
      setChecked(true);
      setInitialValues(savedLoginParams);
    }
  }, [savedLoginParams]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues]);

  const onCheckboxChange = (e: any) => {
    setChecked(e.target.checked);
  };

  const recaptchaRef = React.useRef();

  const handleSubmit = async (values: LoginFormValues): Promise<void> => {
    // @ts-ignore
    const gCaptchaResponse: string = await recaptchaRef.current?.executeAsync();

    onLogin(values.email, values.password, checked, gCaptchaResponse, null);
  };

  const handleSubmitMfa = async (values: LoginFormValuesMfa): Promise<void> => {
    // @ts-ignore
    const gCaptchaResponse: string = await recaptchaRef.current?.executeAsync();
    onLogin(
      values.email,
      values.password,
      checked,
      gCaptchaResponse,
      values.mfaCode
    );
  };

  const onXeroLogin = () => {
    onProviderLogin(Provider.XERO);
  };

  const onGoogleLogin = () => {
    onProviderLogin(Provider.GOOGLE);
  };

  const onMicrosoftLogin = () => {
    onProviderLogin(Provider.MICROSOFT);
  };

  return (
    <Spin spinning={loading} size="large">
      <FormWrapper>
        <Link
          to={
            !location.pathname.includes(routes.login)
              ? !location.pathname.includes(routes.signUp.root) &&
                !location.pathname.includes(routes.resetPassword.root)
                ? routes.main
                : routes.login
              : '#'
          }
        >
          <CenterLogo src={Logo} />
        </Link>
        <Row justify="center">
          <Col span={11}>
            {!mfaResponse?.useMFA && (
              <Typography.Title
                level={2}
                style={{
                  fontSize: '24px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'center',
                }}
              >
                {t('login.welcome')}
                <BoldSpanLargeSize> {t('login.login-title')}</BoldSpanLargeSize>
              </Typography.Title>
            )}
            {mfaResponse?.useMFA ? (
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmitMfa}
                style={{ marginBottom: '30px' }}
              >
                {/* <div style={{ height: '50px' }} /> */}
                <Col style={{ justifyContent: 'center', display: 'flex' }}>
                  <Typography.Title
                    level={4}
                    style={{
                      fontSize: '24px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      textAlign: 'center',
                      color: '#042A2A',
                      width: '383px',
                      marginTop: '70px',
                    }}
                  >
                    Enter the 6-digit code found in your authenticator app
                  </Typography.Title>
                </Col>

                <Col span={12} offset={6}>
                  <Form.Item name="email" hidden>
                    <Input type="hidden" value={mfaResponse?.email} />
                  </Form.Item>
                </Col>
                <Col span={12} offset={6}>
                  <Form.Item name="password" hidden>
                    <Input type="hidden" value={mfaResponse?.password} />
                  </Form.Item>
                </Col>
                <Col style={{ justifyContent: 'center', display: 'flex' }}>
                  <Form.Item
                    name="mfaCode"
                    rules={[
                      { required: true, message: t('signup.required-field') },
                    ]}
                  >
                    <OTPInput
                      id="mfa-code"
                      length={6}
                      size={'large'}
                      style={{
                        fontSize: 17,
                        letterSpacing: 7,
                        textAlign: 'center',
                        width: '380px',
                        marginTop: '25px',
                        columnGap: '10',
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      //onChange={handleMfaChange}
                      //value={mfaCode}
                    />
                  </Form.Item>
                </Col>
                <Col
                  span={12}
                  offset={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '70px',
                  }}
                >
                  <Form.Item>
                    <ScopeButton type="primary" htmlType="submit">
                      {t('login.confirm')}
                    </ScopeButton>
                  </Form.Item>
                </Col>
                <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                  <Link className="link link-text" onClick={()=>navigateToExternalPage(process.env.PUBLIC_URL)} to='#'>
                    {t('forgot-password.back-to-login')}
                  </Link>
                </Col>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.G_CAPTCHA_SITE_KEY}
                />
              </Form>
            ) : (
              <>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  style={{ marginBottom: '30px' }}
                  initialValues={initialValues}
                >
                  <div style={{ height: '50px' }} />
                  <Row>
                    <Col
                      span={12}
                      offset={6}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t('signup.required-field'),
                          },
                          {
                            type: 'email',
                            message: t('signup.invalid-email'),
                          },
                        ]}
                      >
                        <FormInput
                          width="330px"
                          placeholder={t('signup.email')}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={12}
                      offset={6}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t('signup.required-field'),
                          },
                        ]}
                      >
                        <FormPasswordInput
                          placeholder={t('signup.password')}
                          size="large"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={12}
                      offset={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <Link
                        className="link link-text password-link"
                        to={routes.resetPassword.request}
                      >
                        {t('login.forgot-password')}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={12}
                      offset={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '10px',
                      }}
                    >
                      <Form.Item>
                        <ScopeButton type="primary" htmlType="submit">
                          {t('login.login')}
                        </ScopeButton>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col
                    span={12}
                    offset={6}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Checkbox
                      checked={checked}
                      style={{ textAlign: 'left' }}
                      onChange={onCheckboxChange}
                    >
                      {t('login.remember-me')}
                    </Checkbox>
                  </Col>
                  <Row
                    justify={'center'}
                    align={'middle'}
                    style={{ height: '50px' }}
                  >
                    <Col
                      span={4}
                      style={{ backgroundColor: '#d5dce0', height: '1px' }}
                    />
                    <Col
                      span={2}
                      style={{
                        textAlign: 'center',
                        color: '#042A2A',
                        fontSize: '18px',
                        lineHeight: '24px',
                        fontWeight: 400,
                      }}
                    >
                      {t('signup.OR')}
                    </Col>
                    <Col
                      span={4}
                      style={{ backgroundColor: '#d5dce0', height: '1px' }}
                    />
                  </Row>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.G_CAPTCHA_SITE_KEY}
                  />
                  <Row
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                    }}
                  >
                    <Col
                      span={12}
                      offset={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <SSOLoginButton type="primary" onClick={onXeroLogin}>
                        <SSOImg src={ImgWithXeroSignIn} />
                        <SSOLoginSpan>{t('login.xero-login')}</SSOLoginSpan>
                      </SSOLoginButton>
                    </Col>

                    <Col
                      span={12}
                      offset={6}
                      style={{
                        marginTop: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <SSOLoginButton type="primary" onClick={onGoogleLogin}>
                        <SSOImg src={ImgWithGoogleSignIn} />
                        <SSOLoginSpan>{t('login.google-login')}</SSOLoginSpan>
                      </SSOLoginButton>
                    </Col>

{/*                     <Col
                      span={12}
                      offset={6}
                      style={{
                        marginTop: '12px',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <SSOLoginButton type="primary" onClick={onMicrosoftLogin}>
                        <SSOImg height={36} src={ImgWithMicrosoftSignIn} />
                        <SSOLoginSpan>{t('login.microsoft-login')}</SSOLoginSpan>
                      </SSOLoginButton>
                    </Col> */}
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      flexFlow: 'column',
                      marginTop: '34px',
                    }}
                  >
                    <Col
                      span={12}
                      offset={6}
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <div style={{ minWidth: '335px' }}>
                        <BoldSpanMidSize>
                          {t('login.donot-have-account')}
                        </BoldSpanMidSize>
                        <Link
                          className="sign-up-link"
                          to={routes.signUp.signupForm}
                        >
                          {t('login.signup')}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Col>
        </Row>
      </FormWrapper>
    </Spin>
  );
};
