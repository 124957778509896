import {
  MenuOutlined,
  DownloadOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { Form, Popover, Progress, Space, Spin, Tabs } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DataLogActions,
  DataLogStatus,
  MenuSize,
  TaxReportCountry,
  VatTaxReportType,
} from 'store/app/enums';
import { User } from 'store/auth/models';
import {
  ActivityLog,
  ActivityRoleUpdateRequest,
  VatReportData,
  VatRunData,
} from 'store/vat/models';
import { getProviderLink } from 'utils/get-provider-link';
import { renderErrorMessage } from './render-error-messages';
import { VatReturnForms } from './vat-return-forms';
import { RenderTransactions } from './tables/render-transactions';
import { renderReconciliations } from './tables/render-reconciliation';
import { renderMaltaRecap } from './tables/render-malta-recap';
import { renderCyprusRecap } from './tables/render-cyprus-recap';
import { ActivityTab } from './activity-tab';
import { AccountType, JournalType, ReturnFrequency } from 'store/api/enums';
import styled from 'styled-components';
import {
  ContextButton,
  ExportButton,
  IconContextButton,
} from 'components/Custom/buttons';
import { ReactComponent as IconRefresh } from 'assets/images/icons/iconRefresh.svg';
import { ReactComponent as IconExport } from 'assets/images/icons/iconExport.svg';
import { StatusGetter } from 'utils/status-getter';
import { Entity } from 'store/organisation/models';
import { AvatarGroupRenderer } from 'utils/avatar-group-renderer';
import { AvatarSizes } from 'utils/render-avatar';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import Logo from 'assets/images/generateTAX-logo.png';
import { getActionMenuItems } from './get-action-menu-items';
import { getNextButton } from './get-next-button';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import { ExcelFileResponse } from 'store/vat/models/vatReportData';
import Excel, { Column } from 'exceljs';
import { StyledTooltip } from 'components/Custom/Tooltip';
import { MapTaxRates } from 'components/MappingForm/map-tax-rates';
import { Section } from 'store/returns/models/returnData';
import { MappingData, ReturnData } from 'store/returns/models';
import { TaxRateOption } from 'pages/returns';
import { mapTaxRatesStyle } from 'components/MappingForm';
const builder = require('xmlbuilder');

const VatReportWrapper = styled.div`
  .top-wrapper {
    border-bottom: 2px solid #f0f0f0;
    background: white;
    display: flex;
    justify-content: space-between;
    height: 65px;
    position: fixed;
    z-index: 100;
    min-width: 800px;
  }

  .content-wrapper {
    font-family: Inter;
    background: #f3f7f8;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    //min-height: 100vh;
    padding: 40px;
  }

  .form-wrapper {
    padding-left: 20px;
    background: #f3f7f8;
    display: flex;
    flex-direction: column;
  }

  .ant-tabs-nav-wrap {
    padding: 0px 0px 0px 40px;
  }

  .menu-wrapper {
    background: #f3f7f8;
    width: 360px;
    z-index: 102;
  }

  .menu-top-wrapper {
    //border-bottom: 2px solid #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 40px;
    gap: 20px;
    position: absolute;
    z-index: 103;
    right: 0px;
    top: 10px;
  }

  .menu-burger-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100px;
  }
  .menu-burger-icon {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  }

  .burger-on {
    box-shadow: 2px 2px 2px 0px #adc2b94d inset;
    border: 1px solid #dbeae3;
    background: #f3f7f8;
    &:hover,
    &:active {
      border: 1px solid #41aaaf;
    }
  }

  .burger-off {
    border: 1px solid transparent;
    background: none;
    &:hover,
    &:active {
      border: 1px solid #dbeae3;
      background: #f3f7f8;
    }
  }

  .menu-burger-text {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: #042a2a;
  }

  .menu-entity-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  .menu-entity-details {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    //line-height: 18px;
  }

  .tabs-wrapper {
    position: fixed;
    width: 100%;
    align-self: end;
    z-index: 101;
  }

  .ant-progress-text {
    color: #41aaaf !important;
  }
`;

interface VatReportProps {
  loading: boolean;
  success: boolean;
  vatRunData: VatRunData;
  vatReportData?: VatReportData;
  //goBack: (data: VatRunData) => void;
  generatedDate: string;
  reloadReportData: (data: VatRunData, isSaved: boolean) => void;
  currentUser: User;
  //subsectionsMap: Map<string, number>;
  //updateSubsectionsMap: (key: string, value: number) => void;
  loadingPercent: number;
  resetValues: boolean;
  resetFinished: () => void;
  reconciliation?: Map<string, string>;
  pages: number;
  saveReturn: (vatRunData: VatRunData) => void;
  revertData: (vatRunData: VatRunData) => void;
  cancelReturn: (vatRunData: VatRunData) => void;
  setAwaitingApproval: (vatRunData: VatRunData) => void;
  setInternallyApproved: (vatRunData: VatRunData) => void;
  sendForClientApproval: (vatRunData: VatRunData) => void;
  setClientApproved: (vatRunData: VatRunData) => void;
  setNotApproved: () => void;
  cancelNotSaved: (vatRunData: VatRunData) => void;
  updateActivityRole: (
    activityRoleUpdateRequest: ActivityRoleUpdateRequest
  ) => void;
  activityLogs?: ActivityLog[];
  updateNote: (noteText: string, dataLogId: string) => void;
  cannotSave: boolean;
  menuSize: MenuSize;
  currentOrganisation?: Entity;
  retFreq: string;
  excelFileData: ExcelFileResponse;
  onReconnect: () => void;
  getExcelFileForExport: () => void;
  returnData?: ReturnData;
  mappingData?: MappingData;
  prepareTaxRateOptions: () => TaxRateOption[];
  sections?: Section[];
  taxRateOptions?: TaxRateOption[];
  outOfScopeOptions?: TaxRateOption[];
  loackWarningWindow: (vatRunData, actionType) => void;
}

export const VatReport: React.FC<VatReportProps> = ({
  loading,
  success,
  vatRunData,
  vatReportData,
  //goBack,
  generatedDate,
  reloadReportData,
  currentUser,
  //subsectionsMap,
  //updateSubsectionsMap,
  loadingPercent,
  resetValues,
  resetFinished,
  reconciliation,
  pages,
  saveReturn,
  revertData,
  cancelReturn,
  setAwaitingApproval,
  setInternallyApproved,
  sendForClientApproval,
  setClientApproved,
  setNotApproved,
  cancelNotSaved,
  updateActivityRole,
  activityLogs,
  updateNote,
  cannotSave,
  menuSize,
  currentOrganisation,
  retFreq,
  onReconnect,
  getExcelFileForExport,
  excelFileData,
  returnData,
  mappingData,
  prepareTaxRateOptions,
  sections,
  taxRateOptions,
  outOfScopeOptions,
  loackWarningWindow,
}) => {
  const { t } = useTranslation();

  const [showSideMenu, setShowSideMenu] = React.useState<boolean>(true);
  const [journalType, setJournalType] = React.useState<string>(
    vatReportData && vatReportData.summarizedJournals.length === 0
      ? 'DETAILED'
      : 'SUMMARISED'
  );
  const [activeKey, setActiveKey] = React.useState('1');
  const [filteredBox, setFilteredBox] = React.useState(null);
  const [enableDownloadFile, setEnableDownloadFile] =
    React.useState<boolean>(false);

  const [form2] = Form.useForm();

  React.useEffect(() => {
    setActiveKey('1');
  }, []);

  React.useEffect(() => {
    setJournalType(
      vatReportData && vatReportData.summarizedJournals.length === 0
        ? 'DETAILED'
        : 'SUMMARISED'
    );
  }, [vatReportData]);

  React.useEffect(() => {
    async function exportExcelFile() {
      const data = Buffer.from(excelFileData?.file, 'base64'); //toBuffer(excelFileData?.file);
      const workbook = new Excel.Workbook();
      await workbook.xlsx.load(data);

      /* generate array of presidents from the second worksheet */
      const detailedVATReturnWS = workbook.getWorksheet(2); //wb.Sheets[wb.SheetNames[1]];
      const rawData: any = vatReportData?.journals.map((journal) =>
        Object.values(journal)
      );
      detailedVATReturnWS
        ?.insertRow(1, getHeaderForExport(vatReportData?.journals))
        .commit();
      const insertedRows = detailedVATReturnWS?.insertRows(2, rawData);
      insertedRows?.forEach((row) => row.commit());

      /* generate array of presidents from the second worksheet */
      const detailedExceptionsWS = workbook.getWorksheet(3); //wb.Sheets[wb.SheetNames[1]];
      const rawData2: any = vatReportData?.exceptions.map((journal) =>
        Object.values(journal)
      );
      if (rawData2?.length > 0) {
        detailedExceptionsWS
          ?.insertRow(1, getHeaderForExport(vatReportData?.exceptions))
          .commit();
        const insertedRows2 = detailedExceptionsWS?.insertRows(2, rawData2);
        insertedRows2?.forEach((row) => row.commit());
      }

      /*const additionalValuesWS = workbook.getWorksheet(4);
      additionalValuesWS.getCell("C4").value = reconciliation
        ? parseInt(reconciliation["VAT Control Account Code"])
        : 0;*/

      const VATReturnWS = workbook.getWorksheet(1);

      vatReportData?.subsections.forEach((subsection) => {
        if (subsection.cellName && VATReturnWS) {
          VATReturnWS.getCell(subsection.cellName).value = subsection.net;
        }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileExtension = '.xlsx';

      const blob = new Blob([buffer], { type: fileType });
      setEnableDownloadFile(false);
      saveAs(
        blob,
        `${vatRunData.connectionName} - ${vatRunData.taxReportTypeName}` +
          fileExtension
      );
    }
    if (enableDownloadFile && excelFileData) {
      exportExcelFile();
    }
  }, [excelFileData]);

  const providerName = 'xero';

  const mappingTab = () => {
    return (
      <div style={mapTaxRatesStyle}>
        <MapTaxRates
          sections={sections}
          taxRateOptions={taxRateOptions}
          outOfScopeOptions={outOfScopeOptions}
          handleTaxRangeChange={() => {}}
          form={form2}
          mappingData={mappingData}
          taxRates={returnData?.taxRates}
          updateSelectedRates={() => {}}
          setOptions={() => {}}
          prepareTaxRateOptions={prepareTaxRateOptions}
          onSubmit={() => {}}
          resetForm={() => {}}
          addUnmapped={() => {}}
          useCashReverse={false}
          disabled={true}
        />
      </div>
    );
  };
  const tabsHeaderClass =
    menuSize === MenuSize.WIDE ? 'header-narrow' : 'header-wide';

  const downloadPDF = async () => {
    const getTargetElement: any = document.getElementById('vat-to-be-exported');
    const getStatusElement: any = document.getElementById('vat-status');

    const pdf = new jsPDF('p', 'mm', 'a4');
    pdf.addPage();
    await html2canvas(getStatusElement, { scale: 2 }).then((canvas) => {
      //const imgHeight = (canvas.height * 100) / canvas.width;
      const imgData = canvas.toDataURL('img/png');
      pdf.addImage(imgData, 'PNG', 160, 25, 75, 6);
    });

    html2canvas(getTargetElement, { scale: 2 }).then((canvas) => {
      const imgWidth = 185;
      const pageHeight = 270;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const imgData = canvas.toDataURL('img/png');
      let position = 10; // give some top padding to first page

      pdf.addImage(Logo, 'PNG', 5, 5, 45, 8);
      pdf.setFontSize(14);
      pdf.setFont('Inter', 'bold');
      pdf.setFillColor('#042A2A');
      pdf.text(`${vatRunData.connectionName}`, 100, 17, { align: 'center' });
      pdf.setFont('Inter', 'normal');
      pdf.setFontSize(12);
      pdf.text(
        `${vatRunData.taxReportCountry} - ${vatRunData.taxReportTypeName} (${vatRunData.currency})`,
        100,
        22,
        { align: 'center' }
      );
      pdf.text(`Period - ${vatRunData.generationPeriod}`, 100, 27, {
        align: 'center',
      });
      pdf.text(`Generated - ${generatedDate}`, 100, 32, {
        align: 'center',
      });

      pdf.addImage(imgData, 'PNG', 5, 35, 200, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 5, position, 200, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.deletePage(1);
      pdf.save(
        `${vatRunData.connectionName} - ${vatRunData.taxReportTypeName}.pdf`
      );
    });
  };

  const toBuffer = (arrayBuffer) => {
    const buffer = Buffer.alloc(arrayBuffer.byteLength);
    const view = new Uint8Array(arrayBuffer);
    for (let i = 0; i < buffer.length; ++i) {
      buffer[i] = view[i];
    }
    return buffer;
  };

  const getHeaderForExport = (dataToExport): Array<Partial<Column>> => {
    const obj = dataToExport?.length > 0 ? dataToExport[0] : null;
    let headerArr: Array<Partial<Column>> = [];
    if (obj) {
      const headers = Object.keys(obj);
      headerArr = headers.map((key) =>
        key.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      );
    }

    return headerArr;
  };

  const downloadExcel = async () => {
    setEnableDownloadFile(true);
    getExcelFileForExport();
  };

  const renderContextMenu = () => {
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          <IconContextButton
            type="text"
            icon={
              <FilePdfOutlined
                style={{ width: '18px', height: '19px', fontSize: 18 }}
              />
            }
            onClick={downloadPDF}
          >
            Export PDF
          </IconContextButton>
          <IconContextButton
            //disabled
            type="text"
            icon={
              <FileExcelOutlined
                style={{ width: '18px', height: '19px', fontSize: 18 }}
              />
            }
            onClick={downloadExcel}
          >
            Export Excel
          </IconContextButton>
        </Space>
      </div>
    );
  };

  const renderForms = () => {
    if (vatRunData.taxType !== VatTaxReportType.RECAP) {
      return (
        <div
          style={{
            width: '100%',
            maxWidth: '1100px',
            minWidth: '780px',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div className="form-wrapper">
            <div style={{ alignSelf: 'end' }}>
              <Popover
                placement="topLeft"
                arrow={false}
                trigger="click"
                content={renderContextMenu()}
                title={''}
                overlayStyle={{ zIndex: 999 }}
              >
                <ExportButton
                  icon={<IconExport />}
                  style={{ marginBottom: 20 }}
                >
                  <span>Export VAT Return</span>
                </ExportButton>
              </Popover>
            </div>
            <div id="vat-to-be-exported">
              <VatReturnForms
                data={vatReportData}
                taxReportCountry={vatRunData.taxReportCountry}
                setActiveKey={setActiveKey}
                setFilteredBox={setFilteredBox}
                //subsectionsMap={subsectionsMap}
                //updateSubsectionsMap={updateSubsectionsMap}
                resetValues={resetValues}
                resetFinished={resetFinished}
                //dataLogId={vatRunData.dataLogId}
              />
            </div>
          </div>
        </div>
      );
    } else {
      if (vatRunData.taxReportCountry === TaxReportCountry.MALTA)
        return renderMaltaRecap(vatReportData?.contacts);
      else if (vatRunData.taxReportCountry === TaxReportCountry.CYPRUS) {
        return renderCyprusRecap(vatReportData?.contacts);
      }
    }
  };

  const checkClient = () => {
    if (
      AccountType[currentUser.accountType] === AccountType.CLIENT &&
      vatRunData.status &&
      DataLogStatus[vatRunData.status] !==
        DataLogStatus.AWAITING_CLIENT_APPROVAL //&&
      // vatRunData.clientId !== currentUser.accountUuid
    )
      return true;
    if (
      AccountType[currentUser.accountType] === AccountType.CLIENT &&
      vatRunData.status &&
      DataLogStatus[vatRunData.status] ===
        DataLogStatus.AWAITING_CLIENT_APPROVAL &&
      vatRunData.clientId !== currentUser.accountUuid
    )
      return true;
    return false;
  };

  const renderSideMenu = () => {
    const selReturnTypeCountry = returnData?.returnTypesEdit?.find(
      (rt) => rt.id === mappingData?.typeId
    )?.countryName;

    return (
      <>
        {showSideMenu && (
          <div className="menu-wrapper">
            <div id="vat-status">
              <StatusGetter status={vatRunData.status} />
            </div>
            <div className="divider-20" />
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <a
                href={getProviderLink(
                  providerName.toLowerCase(),
                  vatRunData.shortCode
                )}
                style={{ cursor: 'pointer' }}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={
                    providerName
                      ? require(`shared/assets/images/${providerName.toLowerCase()}_small.png`)
                          .default
                      : ''
                  }
                  width={25}
                />
              </a>
              <span className="menu-entity-name">
                {vatRunData.connectionName}
              </span>
            </div>
            <div className="menu-entity-details">
              {`${selReturnTypeCountry} - ${vatRunData.taxReportTypeName} (${vatRunData.currency})`}
              <br />
              Period - {vatRunData.generationPeriod}
              <br />
              Generated - {generatedDate}
            </div>

            {!cannotSave ? (
              <>
                {(!vatRunData.status ||
                  (DataLogStatus[vatRunData.status] !==
                    DataLogStatus.SUBMITTED &&
                    DataLogStatus[vatRunData.status] !==
                      DataLogStatus.CANCELLED)) && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      //gap: 20,
                      margin: '20px 0',
                    }}
                  >
                    <Spin spinning={loading || !success}>
                      {getNextButton(
                        handleMenuClick,
                        vatRunData,
                        currentUser,
                        checkClient
                      )}
                    </Spin>{' '}
                    {!checkClient() && (!loading || success) && (
                      <Popover
                        placement="bottomRight"
                        arrow={false}
                        trigger="click"
                        content={getActionMenuItems(
                          vatRunData,
                          currentUser,
                          handleMenuClick,
                          cannotSave
                        )}
                        title={''}
                        overlayStyle={{ zIndex: 999 }}
                      >
                        <ContextButton
                          type="text" //@ts-ignore
                          bgcolor="white"
                        >
                          <EllipseIcon
                            style={{
                              height: '10px',
                            }}
                          />
                        </ContextButton>
                      </Popover>
                    )}
                  </div>
                )}

                {vatRunData.dataLogId &&
                  vatRunData.status &&
                  DataLogStatus[vatRunData.status] !==
                    DataLogStatus.CANCELLED && (
                    <ActivityTab
                      data={vatRunData}
                      updateActivityRole={updateActivityRole}
                      activityLogs={activityLogs}
                      updateNote={updateNote}
                      currentUser={currentUser}
                    />
                  )}
              </>
            ) : null}
            {renderErrorMessage(vatRunData)}
          </div>
        )}
      </>
    );
  };

  const filterJournals = (boxNumber) => {
    if (filteredBox) {
      const filteredData =
        JournalType[journalType] == JournalType.SUMMARISED
          ? vatReportData?.summarizedJournals?.filter(
              (journal) => journal.selectedValue == boxNumber
            )
          : vatReportData?.journals?.filter(
              (journal) => journal.selectedValue == boxNumber
            );
      return filteredData;
    } else {
      return JournalType[journalType] == JournalType.SUMMARISED
        ? vatReportData?.summarizedJournals
        : vatReportData?.journals;
    }
  };

  const addTabItems = (reportData?: VatReportData): any => {
    return new Array(pages).fill(null).map((_, i) => {
      const id = i + 1;
      let lbl;
      let child;
      switch (id) {
        case 1:
          child = renderForms();
          if (vatRunData.taxType !== VatTaxReportType.RECAP) {
            lbl = 'VAT return';
            break;
          } else {
            if (vatRunData.taxReportCountry === TaxReportCountry.MALTA) {
              lbl = 'Statement';
              break;
            } else if (
              vatRunData.taxReportCountry === TaxReportCountry.CYPRUS
            ) {
              lbl = 'Statement';
              break;
            }
          }
        case 2:
          const count = filterJournals(filteredBox)?.length;

          lbl = `Transactions (${count ? count : 0})`;
          child = (
            <RenderTransactions
              transData={filterJournals(filteredBox)}
              dataToExport={filterJournals(filteredBox)}
              journalType={journalType}
              isException={false}
              setJournalType={setJournalType}
              filteredBox={filteredBox}
              noSumData={
                vatReportData && vatReportData.summarizedJournals.length === 0
              }
            />
          );
          break;
        case 3:
          if (
            (!vatRunData.dataLogId && pages === 4) ||
            (!!vatRunData.dataLogId && pages === 5)
          ) {
            lbl = `Exceptions (${
              reportData?.exceptions?.length
                ? reportData?.exceptions?.length
                : 0
            })`;
            child = (
              <RenderTransactions
                transData={vatReportData?.exceptions}
                dataToExport={vatReportData?.exceptions}
                isException
                noSumData={false}
              />
            );
            break;
          } else {
            lbl = currentOrganisation?.connectedProperly ? 'Mapping' : '';
            child = currentOrganisation?.connectedProperly
              ? mappingTab()
              : null;
            break;
          }

        case 4:
          if (
            (!vatRunData.dataLogId && pages === 4) ||
            (!!vatRunData.dataLogId && pages === 5)
          ) {
            lbl = 'Reconciliation';
            child = renderReconciliations(reconciliation);
            break;
          }
        case 5:
          lbl = currentOrganisation?.connectedProperly ? 'Mapping' : '';
          child = currentOrganisation?.connectedProperly ? mappingTab() : null;
          break;
      }
      return {
        label: lbl,
        key: id.toString(),
        //children: child,
        children: (
          <div
            className={`content-wrapper vat-content ${tabsHeaderClass} scroller-submissions`}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
              }}
            >
              {child}
              <div className="divider-20" />
              <div className="divider-20" />
              <div className="divider-20" />
            </div>
            {renderSideMenu()}
          </div>
        ),
      };
    });
  };

  const handleMenuClick = (key: DataLogActions) => {
    switch (key) {
      case DataLogActions.REVERT_DATA:
        revertData(vatRunData);
        break;
      case DataLogActions.SAVE_VAT_RETURN:
        saveReturn(vatRunData);
        break;
      case DataLogActions.CANCEL_RETURN:
        if (!vatRunData.status) cancelNotSaved(vatRunData);
        else cancelReturn(vatRunData);
        break;
      case DataLogActions.SET_AWAITING_APPROVAL:
        setAwaitingApproval(vatRunData);
        break;
      case DataLogActions.SET_INTERNAL_APPROVED:
        setInternallyApproved(vatRunData);
        break;
      case DataLogActions.SEND_FOR_CLIENT_APPROVAL:
        sendForClientApproval(vatRunData);
        break;
      case DataLogActions.SET_NOT_APPROVED:
        setNotApproved();
        break;
      case DataLogActions.SET_CLIENT_APPROVED:
        setClientApproved(vatRunData);
        break;
      case DataLogActions.MARK_AS_SUBMITTED:
        loackWarningWindow(vatRunData, DataLogActions.MARK_AS_SUBMITTED);
        break;
      case DataLogActions.LOCK_AS_APPROVED:
        loackWarningWindow(vatRunData, DataLogActions.LOCK_AS_APPROVED);
        break;
    }
  };

  const refreshDisable = (data: VatRunData) => {
    const disable =
      (data.status &&
        (DataLogStatus[data.status] === DataLogStatus.LOCKED ||
          DataLogStatus[data.status] === DataLogStatus.SUBMITTED ||
          DataLogStatus[data.status] === DataLogStatus.CANCELLED ||
          DataLogStatus[data.status] === DataLogStatus.NOT_SAVED)) ||
      (data.validRange !== null &&
        data.validRange === false &&
        data.isFirstTime === false);

    return disable;
  };

  const refreshTooltip = (data: VatRunData) => {
    let tooltip = 'Update report data from Xero';
    const disable =
      (data.status &&
        (DataLogStatus[data.status] === DataLogStatus.LOCKED ||
          DataLogStatus[data.status] === DataLogStatus.SUBMITTED ||
          DataLogStatus[data.status] === DataLogStatus.CANCELLED)) ||
      (data.validRange !== null &&
        data.validRange === false &&
        data.isFirstTime === false);

    if (disable) tooltip = 'Cannot refresh locked return';
    else if (!currentOrganisation?.connectedProperly)
      tooltip = 'Entity must be connected to be refreshed';
    return tooltip;
  };

  const onRefreshClick = (data: VatRunData) => {
    let isSaved = true;
    if (
      (data.validRange !== null &&
        data.validRange === true &&
        !data.dataLogId) ||
      data.isFirstTime === true
    ) {
      isSaved = false;
    }
    reloadReportData(data, isSaved);
  };

  const getReturnFreq = () => {
    let freq = '';
    switch (retFreq) {
      case ReturnFrequency.BI_YEARLY:
        freq = '3';
        break;

      default:
        freq = '1';
        break;
    }

    return freq;
  };

  const getCurrency = (currency) => {
    let curr = '';
    switch (currency) {
      case 'EUR':
        curr = 'E';
        break;

      default:
        curr = 'E';
        break;
    }

    return curr;
  };

  const onDownloadXMLClick = (data: VatRunData) => {
    const sales: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'T1'
    )?.net;
    const purchases: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'T2'
    )?.net;
    const goodsTo: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'E1'
    )?.net;
    const goodsFrom: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'E2'
    )?.net;
    const servicesTo: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'ES1'
    )?.net;
    const servicesFrom: any = vatReportData?.subsections?.find(
      (subsec) => subsec.code == 'ES2'
    )?.net;

    const xml = builder
      .create('VAT3', { encoding: 'UTF-8', standalone: 'yes' })
      .att('currency', getCurrency(vatRunData.currency))
      .att('name', vatRunData.connectionName.substring(0, 30))
      .att('regnum', currentOrganisation?.vatNumber)
      .att('startdate', moment(vatRunData.fromDate).format('DD/MM/YYYY'))
      .att('enddate', moment(vatRunData.toDate).format('DD/MM/YYYY'))
      .att('sales', parseInt(sales))
      .att('purchs', parseInt(purchases))
      .att('goodsto', parseInt(goodsTo))
      .att('goodsfrom', parseInt(goodsFrom))
      .att('servicesto', parseInt(servicesTo))
      .att('servicesfrom', parseInt(servicesFrom))
      .att('type', '0') //vatRunData.taxType
      .att('filefreq', getReturnFreq())
      .att('formversion', '1')
      .att('language', 'E')
      .end({ pretty: true });

    //@ts-ignore
    var data = new Blob([xml], { type: 'xml' }); //@ts-ignore
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      `${vatRunData.connectionName}-${vatRunData.taxReportTypeName}.xml`
    );
    tempLink.click();
  };

  const showRefreshButton = () => {
    if (
      (!vatRunData.status ||
        DataLogStatus[vatRunData.status] === DataLogStatus.NOT_SAVED) &&
      ((vatRunData.validRange && vatRunData.validRange == true) ||
        vatRunData.isFirstTime)
    )
      return true;
    if (
      vatRunData.status &&
      DataLogStatus[vatRunData.status] === DataLogStatus.SAVED &&
      AccountType[currentUser.accountType] !== AccountType.CLIENT
    )
      return true;
    if (
      (vatRunData.status &&
        (DataLogStatus[vatRunData.status] === DataLogStatus.LOCKED ||
          DataLogStatus[vatRunData.status] === DataLogStatus.SUBMITTED ||
          DataLogStatus[vatRunData.status] === DataLogStatus.CANCELLED)) ||
      (vatRunData.validRange === false && vatRunData.isFirstTime === false)
    )
      return true;
    return false;
  };

  const onTabChange = (key) => {
    if (key == '1') setFilteredBox(null);
    setActiveKey(key);
  };

  return (
    <VatReportWrapper>
      {vatRunData && (
        <>
          <div className="tabs-wrapper">
            <Spin spinning={false} size="large">
              <Tabs
                //type="card"
                activeKey={activeKey}
                onChange={onTabChange}
                items={addTabItems(vatReportData)}
                //onChange={onTabChange}
                size={'large'}
                tabBarGutter={40}
              />
            </Spin>
          </div>
          <div className={`top-wrapper ${tabsHeaderClass} vat-header`}>
            <div style={{ width: '100%' }} />
          </div>
          <div className={'menu-top-wrapper'}>
            {loadingPercent === 100 && (
              <div
                style={{
                  display: 'flex',
                  width: 330,
                  //flexDirection: 'column',
                  justifyContent: 'end',
                  //alignItems: 'center',
                  paddingLeft: 250,
                }}
              >
                {vatRunData.taxReportCountry ===
                  TaxReportCountry.IRELAND_STANDARD && (
                  <ExportButton
                    icon={<DownloadOutlined />}
                    //disabled={refreshDisable(vatRunData) || !showRefreshButton()}
                    onClick={() => onDownloadXMLClick(vatRunData)}
                    loading={loading || !success}
                    style={{ zIndex: 200, marginRight: 20 }}
                  >
                    Export XML
                  </ExportButton>
                )}
                <StyledTooltip //@ts-ignore
                  title={
                    showRefreshButton() &&
                    !refreshDisable(vatRunData) &&
                    refreshTooltip(vatRunData)
                  }
                  placement={'bottom'}
                >
                  <ExportButton
                    icon={<IconRefresh />}
                    disabled={
                      refreshDisable(vatRunData) ||
                      !showRefreshButton() ||
                      AccountType[currentUser.accountType] ===
                        AccountType.CLIENT
                    }
                    onClick={() =>
                      currentOrganisation?.connectedProperly
                        ? onRefreshClick(vatRunData)
                        : onReconnect()
                    }
                    loading={loading || !success}
                    style={{ zIndex: 200 }}
                  >
                    {currentOrganisation?.connectedProperly
                      ? 'Refresh'
                      : 'Reconnect'}
                  </ExportButton>
                </StyledTooltip>
              </div>
            )}
            {loadingPercent !== 100 && (
              <div
                style={{
                  //display: 'flex',
                  width: 220,
                  //flexDirection: 'column',
                }}
              >
                <div className="menu-entity-details">
                  {loadingPercent === 100 ? 'Data loaded' : 'Loading data ...'}
                </div>
                <Progress
                  percent={loadingPercent}
                  type={'line'}
                  //type="circle"
                  strokeColor="#41AAAF"
                  //size={'small'}
                />
              </div>
            )}
            {currentOrganisation &&
              AccountType[currentUser.accountType] !== AccountType.CLIENT && (
                <AvatarGroupRenderer
                  entity={currentOrganisation}
                  maxCount={4}
                  size={AvatarSizes.DEFAULT}
                />
              )}

            <div className="menu-burger-button">
              <div
                className={`menu-burger-icon ${
                  showSideMenu ? 'burger-on' : 'burger-off'
                }`}
                onClick={() => setShowSideMenu(!showSideMenu)}
              >
                <MenuOutlined />
              </div>
              <span className="menu-burger-text">
                {showSideMenu ? 'Hide Menu' : 'Show Menu'}
              </span>
            </div>
          </div>
        </>
      )}
    </VatReportWrapper>
  );
};
