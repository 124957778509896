import {
  Col,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Typography,
  Tabs,
  Popover,
  Switch,
  Image,
} from 'antd';
import React from 'react';
import { User } from 'store/auth/models';
import { Country, CountryFlag } from 'store/common/models';
import { LoginDetails } from 'store/user/models';
import moment from 'moment';
import { ReactComponent as EllipseIcon } from 'assets/images/icons/ellipsesIcon.svg';
import { ReactComponent as EditIcon } from 'assets/images/icons/editIcon.svg';
import { ReactComponent as UserIcon } from 'assets/images/icons/userIcon.svg';
import { ReactComponent as CrossIcon } from 'assets/images/icons/crossIcon.svg';
import ImgWithXeroSignIn from 'assets/images/xero_icon.png';
import ImgWithGoogleSignIn from 'assets/images/google-icon.png';
import defaultUserPicture from '../../assets/images/default-image.svg';
import { useTranslation } from 'react-i18next';
import { AccountType } from 'store/api/enums';
import { ChangePasswordForm } from 'components/AccountComponent/change-password';
import { History } from 'components/UsersComponent/history';
import {
  ContextButton,
  IconContextButton,
  ScopeButton,
} from 'components/Custom/buttons';
import { StyledCard } from 'components/Custom/card';
import {
  BlackSpan,
  ColoredTag,
  GraySpan,
  CardTitleSpan,
  CardDescSpan,
} from 'components/Custom/spans';
import { SSOImg } from 'components/Custom/images';
import { EyeOutlined } from '@ant-design/icons';
import { FormInput } from 'components/Custom/inputs';
import { FormSelect } from 'components/Custom/selects';
import { LabelWrapper } from 'components/Custom/labels';
import { renderPhoneCodeOption } from 'utils/render-countries-codes';
import { ProfileWrapper } from 'components/Custom/wrappers';
import { StyledPopconfirm } from 'components/Custom/Popconfirm';

interface AccountComponentProps {
  loading: boolean;
  currentUser?: User;
  selectedAccount?: User;
  history?: LoginDetails[];
  connectXeroAccount: () => void;
  disconnectXeroAccount: () => void;
  disconnectGoogleAccount: () => void;
  openMFAWindow: () => void;
  removeMfa: () => void;
  deleteProfile: () => void;
  savePassword: (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string
  ) => void;
  openAccessRights: (accountId) => void;
  openEditImageWindow: () => void;
  onDeleteUser: (userToDeleteId: string) => void;
  countries: Country[];
  countriesExternalData: CountryFlag[];
  openEditDetails: () => void;
  openChangeOwnerForm: () => void;
}
const { Paragraph } = Typography;
export const AccountComponent: React.FC<AccountComponentProps> = ({
  loading,
  currentUser,
  selectedAccount,
  history,
  connectXeroAccount,
  disconnectXeroAccount,
  disconnectGoogleAccount,
  openMFAWindow,
  removeMfa,
  deleteProfile,
  savePassword,
  openAccessRights,
  openEditImageWindow,
  onDeleteUser,
  countries,
  countriesExternalData,
  openEditDetails,
  openChangeOwnerForm,
}) => {
  const { t } = useTranslation();
  const [formPassword] = Form.useForm();
  const onXeroAccountConnect = () => connectXeroAccount();
  const onXeroAccountDisconnect = () => disconnectXeroAccount();
  const onGoogleAccountDisconnect = () => disconnectGoogleAccount();
  const onEnableMFAClick = () => openMFAWindow();
  const onRemoveMfaClick = () => removeMfa();

  const profilePicture: string = selectedAccount
    ? selectedAccount.profilePicture
      ? selectedAccount.profilePicture
      : defaultUserPicture
    : defaultUserPicture;

  const isCurrentUser = selectedAccount?.userUuid === currentUser?.userUuid;
  const renderAccountCard = () => {
    return (
      <StyledCard //@ts-ignore
        stopShadowBox
        width={'99%'}
        height={'104'}
      >
        <Space style={{ columnGap: '35.5%' }}>
          <Space direction="vertical" style={{ rowGap: 4, minWidth: 422 }}>
            <CardTitleSpan>{t('common.account')}</CardTitleSpan>
            <Space style={{ columnGap: 15 }}>
              <CardDescSpan>{`${t('users.ip-address')}: ${
                history && history[0].loginIP
              }`}</CardDescSpan>
              <span>.</span>
              <CardDescSpan>{`${t('users.last-login')}: ${
                history &&
                moment(history[0]?.loginTimestamp).format('DD-MM-YYYY hh:mm:ss')
              }`}</CardDescSpan>
            </Space>
          </Space>
          <a
            target="_blank"
            href={`https://whatismyipaddress.com/ip/${
              history && history[0].loginIP
            }`}
          >
            <ScopeButton
              type="default" //@ts-ignore
              width="154px"
              height="32px"
              //onClick={() => onClose()}
            >
              {t('users.verify-location')}
            </ScopeButton>
          </a>
        </Space>
      </StyledCard>
    );
  };

  const renderUserPermissionsCard = () => {
    return (
      <StyledCard //@ts-ignore
        stopShadowBox
        width={'99%'}
        height={'104'}
      >
        <Space style={{ columnGap: '31%' }}>
          <Space direction="vertical" style={{ rowGap: 4, minWidth: 446 }}>
            <CardTitleSpan>{t('users.user-permissions')}</CardTitleSpan>
            <Space style={{ columnGap: 15 }}>
              <CardDescSpan>
                You can assign which entities this user has access to view and
                use.
              </CardDescSpan>
            </Space>
          </Space>
          <ScopeButton
            type="default" //@ts-ignore
            width="154px"
            height="32px"
            onClick={() => openAccessRights(selectedAccount?.accountUuid)}
          >
            {t('users.access-rights')}
          </ScopeButton>
        </Space>
      </StyledCard>
    );
  };

  const renderAssociatedAccountsCard = () => {
    return (
      <StyledCard //@ts-ignore
        stopShadowBox
        width={'99%'}
        height={'104'}
      >
        <Space direction="vertical" style={{ rowGap: 30 }}>
          <Space style={{ columnGap: 100 }}>
            <Space direction="vertical" style={{ rowGap: 4 }}>
              <CardTitleSpan>{t('users.associated-accounts')}</CardTitleSpan>
              <Space style={{ columnGap: 15 }}>
                <CardDescSpan style={{ width: 460, display: 'block' }}>
                  {t('users.associated-accounts-desc')}
                </CardDescSpan>
              </Space>
            </Space>
          </Space>
          <Space direction="vertical">
            <Space style={{ columnGap: '141%' }}>
              <Space style={{ columnGap: 20, minWidth: 170 }}>
                <SSOImg src={ImgWithXeroSignIn} />
                <CardTitleSpan style={{ fontSize: '14px', fontWeight: 500 }}>
                  {t('users.xero-accounting')}
                </CardTitleSpan>
              </Space>
              {selectedAccount?.xeroEnabled ? (
                <StyledPopconfirm // @ts-ignore
                  title="Disconnect Account"
                  description={
                    <span>
                      Are you sure that you want to disconnect from Xero?
                    </span>
                  }
                  onConfirm={onXeroAccountDisconnect}
                  placement="top"
                  okText={t('common.yes')}
                  cancelText={t('common.cancel')}
                >
                  <ScopeButton
                    type="default" //@ts-ignore
                    width="154px"
                    height="32px"
                    //onClick={onXeroAccountDisconnect}
                  >
                    {t('common.disconnect')}
                  </ScopeButton>
                </StyledPopconfirm>
              ) : (
                <ScopeButton
                  type="primary" //@ts-ignore
                  width="154px"
                  height="32px"
                  onClick={onXeroAccountConnect}
                >
                  {t('common.connect')}
                </ScopeButton>
              )}
            </Space>
            {/* <Space style={{ columnGap: '130%' }}>
              <Space style={{ columnGap: 20 }}>
                <SSOImg src={ImgWithGoogleSignIn} />
                <CardTitleSpan
                  style={{
                    fontSize: '14px',
                    fontWeight: 500,
                    width: '130px',
                    display: 'block',
                  }}
                >
                  {t('users.google')}
                </CardTitleSpan>
              </Space>
              <ScopeButton
                type="default" //@ts-ignore
                width="154px"
                height="32px"
                onClick={onGoogleAccountDisconnect}
              >
                {t('common.disconnect')}
              </ScopeButton>
            </Space> */}
          </Space>
        </Space>
      </StyledCard>
    );
  };

  const renderMFACard = () => {
    return (
      <StyledCard //@ts-ignore
        stopShadowBox
        width={'99%'}
        height={'104'}
      >
        <Space style={{ columnGap: 110 }}>
          <Space direction="vertical" style={{ rowGap: 4 }}>
            <CardTitleSpan>{t('users.mfa-authentication')}</CardTitleSpan>
            <Space style={{ columnGap: 15 }}>
              <CardDescSpan> {t('users.mfa-authentication-desc')}</CardDescSpan>
            </Space>
          </Space>
          <Space style={{ marginRight: 8 }}>
            <GraySpan>
              {selectedAccount?.mfa ? t('common.on') : t('common.off')}
            </GraySpan>
            <Switch
              className={selectedAccount?.mfa ? 'switch-checked' : ''}
              checked={selectedAccount?.mfa}
              disabled={!isCurrentUser}
              onChange={(e) => {
                if (selectedAccount?.mfa) {
                  onRemoveMfaClick();
                } else {
                  onEnableMFAClick();
                }
              }}
            />
          </Space>
        </Space>
      </StyledCard>
    );
  };

  const renderPasswordCard = () => {
    return (
      <StyledCard //@ts-ignore
        stopShadowBox
        width={'99%'}
        height={'104'}
      >
        <Space style={{ columnGap: 410 }}>
          <Space direction="vertical" style={{ rowGap: 4 }}>
            <CardTitleSpan>{t('signup.password')}</CardTitleSpan>
            <Space style={{ columnGap: 15, marginTop: 25 }}>
              <ChangePasswordForm
                savePassword={savePassword}
                form={formPassword}
              />
            </Space>
          </Space>
        </Space>
      </StyledCard>
    );
  };

  const renderAccountAndSecurityTab = () => {
    return (
      <Space direction="vertical" style={{ rowGap: 24, marginTop: '25px' }}>
        <div>{renderAccountCard()}</div>
        {selectedAccount && <div> {renderUserPermissionsCard()}</div>}
        <div>{renderAssociatedAccountsCard()}</div>
        <div>{renderMFACard()}</div>
        <div>{isCurrentUser && renderPasswordCard()}</div>
      </Space>
    );
  };

  const renderCountryWithFlag = (country: Country): React.ReactNode => {
    return (
      <div style={{ display: 'flex' }}>
        <img
          width={'24px'}
          height={'16px'}
          alt={''}
          src={country.flag}
          style={{
            marginLeft: '5px',
            marginRight: '10px',
            marginTop: '3px',
          }}
        />
        {country.name}
      </div>
    );
  };

  const renderBillingDetailsTab = () => {
    return (
      <StyledCard //@ts-ignore
        stopShadowBox
        width={'773px'}
        height={'640px'}
        style={{ marginTop: '25px' }}
      >
        <CardTitleSpan>{t('users.billing-details')}</CardTitleSpan>
        <Form onFinish={() => {}} style={{ paddingTop: '40px' }}>
          <Row justify={'center'} align={'middle'}>
            <Col span={12}>
              <Form.Item
                name="addressLine1"
                rules={[
                  { required: true, message: t('signup.required-field') },
                ]}
              >
                <LabelWrapper>
                  <span className="label-text">
                    {t('signup.address-line1')}
                  </span>
                </LabelWrapper>
                <FormInput
                  width="95%" //@ts-ignore
                  border_color="#DBEAE3"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="addressLine2">
                <LabelWrapper>
                  <span className="label-text">
                    {t('signup.address-line2')}
                  </span>
                </LabelWrapper>
                <FormInput
                  width="95%" //@ts-ignore
                  border_color="#DBEAE3"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="town"
                rules={[
                  { required: true, message: t('signup.required-field') },
                ]}
              >
                <LabelWrapper>
                  <span className="label-text">{t('signup.town')}</span>
                </LabelWrapper>
                <FormInput
                  width="95%" //@ts-ignore
                  border_color="#DBEAE3"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="country"
                rules={[
                  { required: true, message: t('signup.required-field') },
                ]}
              >
                <LabelWrapper>
                  <span className="label-text">{t('signup.country')}</span>
                </LabelWrapper>
                <FormSelect //@ts-ignore
                  width="95%"
                  height="45px"
                  bgcolor="#FFFFFF"
                  bordercolor="#DBEAE3"
                >
                  {countries &&
                    countries.map((c) => (
                      <Select.Option key={c.iso} value={c.iso}>
                        {renderCountryWithFlag(c)}
                      </Select.Option>
                    ))}
                </FormSelect>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="zipCode"
                rules={[
                  { required: true, message: t('signup.required-field') },
                ]}
              >
                <LabelWrapper>
                  <span className="label-text">{t('signup.zip-code')}</span>
                </LabelWrapper>
                <FormInput
                  width="95%" //@ts-ignore
                  border_color="#DBEAE3"
                />
              </Form.Item>
            </Col>
            <Col span={12} style={{ display: 'flex' }}>
              <Form.Item
                name="code"
                rules={[
                  { required: true, message: t('signup.required-field') },
                ]}
                style={{ width: '29%', marginRight: '2%' }}
              >
                <LabelWrapper>
                  <span className="label-text">{t('signup.code')}</span>
                </LabelWrapper>
                <FormSelect //@ts-ignore
                  width={'95%'}
                  height="45px"
                  bordercolor="#DBEAE3"
                  dropdownStyle={{ minWidth: '330px' }}
                  optionLabelProp="value"
                  id="counties-code"
                  bgcolor="#FFFFFF"
                >
                  {countriesExternalData &&
                    countriesExternalData.map((c) => renderPhoneCodeOption(c))}
                </FormSelect>
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  { required: true, message: t('signup.required-field') },
                ]}
                style={{ width: '68%' }}
              >
                <LabelWrapper>
                  <span className="label-text">{t('signup.phone')}</span>
                </LabelWrapper>
                <FormInput
                  width={'95%'} //@ts-ignore
                  border_color="#DBEAE3"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: t('signup.required-field') },
                  {
                    type: 'email',
                    message: t('signup.invalid-email'),
                  },
                ]}
              >
                <LabelWrapper>
                  <span className="label-text">{t('signup.email')}</span>
                </LabelWrapper>
                <FormInput
                  width="95%" //@ts-ignore
                  border_color="#DBEAE3"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="vat"
                rules={[
                  { required: true, message: t('signup.required-field') },
                ]}
              >
                <LabelWrapper>
                  <span className="label-text">{t('main.VAT')}</span>
                </LabelWrapper>
                <FormInput
                  width="95%" //@ts-ignore
                  border_color="#DBEAE3"
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <ScopeButton //@ts-ignore
              width="172px"
              height="40px"
              type="primary"
              htmlType="submit"
            >
              {t('common.save')}
            </ScopeButton>
          </Form.Item>
        </Form>
      </StyledCard>
    );
  };

  const items = [
    {
      label: t('users.account-security'),
      key: 'Account & Security',
      children: renderAccountAndSecurityTab(),
    },
    /*     {
      label: t('users.billing-details'),
      key: 'Billing Details',
      children: renderBillingDetailsTab(),
    }, */
    {
      label: t('users.login-history'),
      key: 'Login History',
      children: (
        <StyledCard //@ts-ignore
          stopShadowBox
          width={'773px'}
          height={'640px'}
          style={{ marginTop: '25px' }}
        >
          <History history={history} profileMode={true} onClose={() => {}} />
        </StyledCard>
      ),
    },
  ];

  const renderRole = (content: string) => {
    switch (AccountType[content]) {
      case AccountType.OWNER:
        return (
          <ColoredTag
            color="#042A2A" //@ts-ignore
            bgcolor="#FBE3FF"
          >
            {AccountType[content]}
          </ColoredTag>
        );
        break;
      case AccountType.CLIENT:
        return (
          <ColoredTag
            color="#042A2A" //@ts-ignore
            bgcolor="#CCF5FE"
          >
            {AccountType[content]}
          </ColoredTag>
        );
        break;
      case AccountType.STAFF:
        return (
          <ColoredTag
            color="#042A2A" //@ts-ignore
            bgcolor="#F9EEC7"
          >
            {AccountType[content]}
          </ColoredTag>
        );
        break;
      default:
        break;
    }
  };

  const renderContextMenu = (record) => {
    const to = `/entities/organisation/${record?.organisationId}`;
    return (
      <div className="context-menu-items">
        <Space direction="vertical" style={{ rowGap: '0' }}>
          {isCurrentUser && (
            <IconContextButton
              type="text"
              icon={
                <EditIcon
                  style={{ width: '16px', height: '17px', marginRight: '7px' }}
                />
              }
              onClick={() => openEditDetails()}
            >
              {t('users.edit-details')}
            </IconContextButton>
          )}
          {currentUser?.accountType &&
            AccountType[currentUser.accountType] === AccountType.OWNER && (
              <IconContextButton
                type="text"
                icon={
                  <UserIcon
                    style={{
                      width: '18px',
                      height: '20px',
                      marginRight: '4px',
                    }}
                  />
                }
                onClick={() => openChangeOwnerForm()}
              >
                {t('users.change-owner')}
              </IconContextButton>
            )}
          {!record?.isLicenseOwner &&
          record?.userUuid !== currentUser?.userUuid ? (
            <>
              {currentUser?.canManageUsers && (
                <StyledPopconfirm // @ts-ignore
                  title={t('users.delete-user-title')}
                  description={
                    <span>
                      {t('users.delete-user-description').split('{br}')[0]}
                      <br />
                      {t('users.delete-user-description').split('{br}')[1]}
                    </span>
                  }
                  onConfirm={() => onDeleteUser(record.userUuid)}
                  placement="left"
                  okText={t('common.yes')}
                  cancelText={t('common.cancel')}
                >
                  <IconContextButton
                    type="text"
                    icon={
                      <CrossIcon
                        style={{
                          width: '10px',
                          height: '22px',
                          marginRight: '8px',
                          marginLeft: '5px',
                        }}
                      />
                    }
                  >
                    {t('users.delete-user')}
                  </IconContextButton>
                </StyledPopconfirm>
              )}
            </>
          ) : (
            <StyledPopconfirm // @ts-ignore
              placement="top"
              title="Delete account"
              description={
                <>
                  <Paragraph>
                    You can cancel your generate.TAX account whenever you want.
                    <br />
                    Your generate.TAX account will be terminated including all
                    <br />
                    your connected organisations, users and billing
                    <br />
                    subscriptions. If you wish to pause the account instead of
                    <br />
                    deleting the account, you may delete the Organisations
                    <br />
                    without cancelling the subscription.
                  </Paragraph>
                  {'Are you sure that you want to delete account?'}
                </>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={deleteProfile}
            >
              <IconContextButton
                type="text"
                icon={
                  <CrossIcon
                    style={{
                      width: '10px',
                      height: '22px',
                      marginRight: '8px',
                      marginLeft: '5px',
                    }}
                  />
                }
              >
                {t('users.delete-user')}
              </IconContextButton>{' '}
            </StyledPopconfirm>
          )}
        </Space>
      </div>
    );
  };

  const renderExtraButtons = (record?: User) => {
    if (
      currentUser?.accountType &&
      AccountType[currentUser.accountType] === AccountType.OWNER &&
      currentUser.accountUuid === record?.accountUuid
    ) {
      return (
        <Popover
          placement="topLeft"
          arrow={false}
          trigger="click"
          content={renderContextMenu(record)}
          title={''}
          overlayStyle={{ zIndex: 999 }}
        >
          <ContextButton type="text">
            <EllipseIcon
              style={{
                height: '10px',
              }}
            />
          </ContextButton>
        </Popover>
      );
    }
  };

  return (
    <ProfileWrapper>
      <Spin size="large" spinning={loading}>
        <Row>
          <Col
            span={20}
            xs={{
              offset: 0,
            }}
            sm={{ offset: 0 }}
            md={{ offset: 0 }}
            lg={{
              offset: 0,
            }}
            xl={{
              offset: 0,
              span: 24,
            }}
            xxl={{
              offset: 2,
              span: 16,
            }}
          >
            <Space align="start" style={{ columnGap: '25px' }}>
              <div style={{ height: '100vh', position: 'fixed' }}>
                <StyledCard>
                  {isCurrentUser ? (
                    <>
                      <img
                        height={150}
                        width={150}
                        className="profile-rounded-image"
                        alt=""
                        src={
                          profilePicture.startsWith('data')
                            ? profilePicture
                            : `data:image/png;base64,${profilePicture}`
                        }
                      />
                      <div
                        className="mask"
                        onClick={() => openEditImageWindow()}
                      >
                        <EyeOutlined style={{ margin: '4px' }} />
                        <span>{t('common.preview')}</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <Image
                        height={150}
                        width={150}
                        className="profile-rounded-image"
                        preview={
                          profilePicture === defaultUserPicture
                            ? false
                            : {
                                destroyOnClose: true,
                                toolbarRender: () => null,
                              }
                        }
                        alt=""
                        src={
                          profilePicture?.startsWith('data')
                            ? profilePicture
                            : `data:image/png;base64,${profilePicture}`
                        }
                      />
                    </>
                  )}
                  {/*  <Image
                height={150}
                width={150}
                className="profile-rounded-image"
                preview={{
                  destroyOnClose: true,
                  imageRender: ()=> openEditImageWindow(),
                  toolbarRender: () => null,
                }}
                //visible={}
                src={profilePicture}
              />*/}
                  <Typography
                    style={{
                      fontSize: '18px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      textAlign: 'center',
                      color: '#042A2A',
                      marginBottom: '10px',
                    }}
                  >
                    {`${selectedAccount?.firstName} ${selectedAccount?.lastName}`}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '13px',
                      fontWeight: '400',
                      lineHeight: '18px',
                      textAlign: 'center',
                      color: '#042A2A',
                      marginBottom: '10px',
                    }}
                  >
                    {selectedAccount?.email}
                  </Typography>
                  <div style={{ margin: ' 0 36%' }}>
                    {selectedAccount?.accountType &&
                      renderRole(selectedAccount?.accountType)}
                  </div>
                  <Divider />
                  <Space direction="vertical" style={{ marginBottom: '20px' }}>
                    {selectedAccount?.companyName && (
                      <Space direction="vertical" style={{ rowGap: 0 }}>
                        <GraySpan>{t('signup.company-name')}</GraySpan>
                        <BlackSpan>{selectedAccount?.companyName}</BlackSpan>
                      </Space>
                    )}

                    {selectedAccount?.phoneNumber && (
                      <Space direction="vertical" style={{ rowGap: 0 }}>
                        <GraySpan>{t('signup.phone')}</GraySpan>
                        <BlackSpan>{selectedAccount?.phoneNumber}</BlackSpan>
                      </Space>
                    )}

                    {selectedAccount?.website && (
                      <Space direction="vertical" style={{ rowGap: 0 }}>
                        <GraySpan>{t('common.website')}</GraySpan>
                        <BlackSpan>{selectedAccount?.website}</BlackSpan>
                      </Space>
                    )}

                    {selectedAccount?.timezone && (
                      <Space direction="vertical" style={{ rowGap: 0 }}>
                        <GraySpan>{t('common.timezone')}</GraySpan>
                        <BlackSpan>{selectedAccount?.timezone}</BlackSpan>
                      </Space>
                    )}
                  </Space>

                  {isCurrentUser && (
                    <ScopeButton
                      type="default" //@ts-ignore
                      width="100%"
                      height="32px"
                      onClick={() => openEditDetails()}
                    >
                      {t('common.edit')}
                    </ScopeButton>
                  )}
                </StyledCard>
              </div>
              <StyledCard //@ts-ignore
                height="1090px"
                width="851px"
                style={{
                  marginLeft: '300px',
                  maxWidth: '1000px',
                  minWidth: '900px',
                }}
              >
                <Tabs
                  defaultActiveKey="1"
                  items={
                    isCurrentUser
                      ? items
                      : items.filter((item) => item.key != 'Billing Details')
                  }
                  tabBarExtraContent={{
                    right: renderExtraButtons(selectedAccount),
                  }}
                />
              </StyledCard>
            </Space>
          </Col>
        </Row>
      </Spin>
    </ProfileWrapper>
  );
};
