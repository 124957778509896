import { Form } from 'antd';
import { AccountComponent } from 'components/AccountComponent';
import { QRCodeSetup } from 'components/AccountComponent/qr-code-setup';
import { Rights } from 'components/UsersComponent/rights';
import { MessageStates } from 'containers/MessageBox';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeOwnerRequest,
  changePasswordRequest,
  deleteAccountRequest,
  disableMfaRequest,
  disconnectProviderRequest,
  enableMfaRequest,
  fetchConnectUrlRequest,
  fetchProfilePictureRequest,
  fetchQRCodeRequest,
  updateDetailsRequest,
  uploadProfilePictureRequest,
} from 'store/account/actions';
import {
  getAccountLoading,
  getAccountProfilePicture,
  getAccountQRCodeData,
} from 'store/account/selectors';
import { AccountType, Provider, TablePermission } from 'store/api/enums';
import {
  turnDrawerOff,
  turnDrawerOn,
  turnMessageOn,
  turnModalOff,
  turnModalOn,
} from 'store/app/actions';
import { CurrentModal, DrawerType, WindowType } from 'store/app/enums';
import { getModalOpen } from 'store/app/selectors';
import { DrawerData, MessageData, ModalData } from 'store/app/types';
import { User } from 'store/auth/models';
import { getAuthUser } from 'store/auth/selectors';
import {
  fetchCountriesExternalDataRequest,
  fetchTimezonesRequest,
} from 'store/common/actions';
import {
  getCommonCountries,
  getCommonLoading,
  getCommonTimezones,
  getCountriesExternalData,
} from 'store/common/selectors';
import { getOrgMappedOrganisations } from 'store/organisation/selectors';
import {
  deleteUserRequest,
  fetchUserHistoryRequest,
  fetchUserRightsRequest,
  fetchUsersRequest,
  setUserRightsRequest,
} from 'store/user/actions';
import {
  getUserHistory,
  getUserLoading,
  getUserMembers,
  getUserRights,
} from 'store/user/selectors';
import PicPage from './pic-page';
import { useParams } from 'react-router';
import { ContactDetailsForm } from 'components/AccountComponent/contact-details-form';
import { ChangeOwner } from 'components/AccountComponent/change-owner';
import { UserRights } from 'store/user/models';
import { push } from 'connected-react-router';
import routes from 'routes';

export interface StaffMember {
  id: string;
  name: string;
}

export const AccountPage: React.FC = () => {
  const dispatch = useDispatch();
  const parameters: any = useParams();
  const accountId = parameters.accountId;
  const errorId = parameters.errorId;
  const currentUser = useSelector(getAuthUser);
  const commonLoading = useSelector(getCommonLoading);
  const userLoading = useSelector(getUserLoading);
  const accountLoading = useSelector(getAccountLoading);
  const timezones = useSelector(getCommonTimezones);
  const userHistory = useSelector(getUserHistory);
  const QRCodeData = useSelector(getAccountQRCodeData);
  const modalOpen = useSelector(getModalOpen);
  const members = useSelector(getUserMembers);
  const loading = commonLoading || userLoading || accountLoading;
  const rights = useSelector(getUserRights);
  const mappedOrganisations = useSelector(getOrgMappedOrganisations);
  const countriesExternalData = useSelector(getCountriesExternalData);
  const countries = useSelector(getCommonCountries);
  const [formPassword] = Form.useForm();
  const [formContactDetails] = Form.useForm();
  const [staffMembers, setStaffMembers] = React.useState<StaffMember[]>([]);
  const [currentModal, setCurrentModal] = React.useState<CurrentModal>(
    CurrentModal.NONE
  );

  const [selectedAccount, setSelectedAccount] = React.useState<User>();

  const { t } = useTranslation();

  const saveUserRights = (
    userRight: UserRights,
    tablePermission: TablePermission
  ) => {
    dispatch(setUserRightsRequest(userRight, tablePermission));
  };

  const uploadImage = (base64: File) => {
    currentUser &&
      dispatch(uploadProfilePictureRequest(base64, currentUser?.userUuid));
    dispatch(turnModalOff());
  };

  const openAccessRights = (accountId) => {
    dispatch(fetchUserRightsRequest(accountId));
    setCurrentModal(CurrentModal.RIGHTS);
  };

  const openEditDetails = () => {
    const modalData: DrawerData = {
      type: DrawerType.EDIT,
      title: t('users.edit-details'),
      component: (
        <ContactDetailsForm
          updateContact={updateContact}
          form={formContactDetails}
          timezones={timezones}
          countriesExternalData={countriesExternalData}
        />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const openChangeOwnerForm = () => {
    const modalData: DrawerData = {
      type: DrawerType.CHANGE_OWNER,
      title: t('users.change-owner'),
      component: (
        <ChangeOwner updateOwner={updateOwner} staffMembers={staffMembers} />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const savePassword = (
    oldPassword: string,
    newPassword: string,
    newPasswordConfirm: string
  ) => {
    if (oldPassword === newPassword) {
      const message: MessageData = {
        title: 'Change password failed',
        description: 'Old and new password cannot be the same',
        type: MessageStates.ERROR,
      };
      dispatch(turnMessageOn(message));
    } else if (newPassword === newPasswordConfirm) {
      dispatch(changePasswordRequest(oldPassword, newPassword));
    } else {
      const message: MessageData = {
        title: 'Change password failed',
        description: 'Re-typed password does not match the new password',
        type: MessageStates.ERROR,
      };
      dispatch(turnMessageOn(message));
    }
  };

  const openEditImageWindow = () => {
    const modalData: ModalData = {
      type: WindowType.IMAGE,
      title: t('common.edit-profile-picture'),
      component: (
        <PicPage
          profilePicture={selectedAccount?.profilePicture}
          uploadImage={uploadImage}
          onClose={() => dispatch(turnModalOff())}
        />
      ),
    };

    dispatch(turnModalOn(modalData));
  };

  const onVerifyCode = (code: string) => {
    const secretKey = QRCodeData?.secretKey ? QRCodeData?.secretKey : '';
    dispatch(enableMfaRequest(code, secretKey));
  };

  const openMFAWindow = () => {
    const modalData: DrawerData = {
      type: DrawerType.CHANGE_OWNER,
      title: 'Set up Authenticator app',
      component: (
        <QRCodeSetup QRCodeData={QRCodeData} onVerifyCode={onVerifyCode} />
      ),
    };

    dispatch(turnDrawerOn(modalData));
  };

  const deleteUser = (userToDeleteId: string) => {
    currentUser &&
      userToDeleteId &&
      dispatch(deleteUserRequest(userToDeleteId));
  };

  React.useEffect(() => {
    if (rights && currentModal === CurrentModal.RIGHTS) {
      const modalData: DrawerData = {
        type: DrawerType.RIGHTS,
        title: t('users.access-rights'),
        component: !loading && rights && (
          <Rights
            rights={rights}
            saveUserRights={saveUserRights}
            entities={mappedOrganisations}
            onClose={() => dispatch(turnDrawerOff())}
          />
        ),
      };

      dispatch(turnDrawerOn(modalData));
    }
  }, [rights]);

  React.useEffect(() => {
    modalOpen && formPassword.resetFields();
  }, [modalOpen]);

  React.useEffect(() => {
    const staffMembers: StaffMember[] = [];
    if (members) {
      members.map((m) => {
        if (
          AccountType[m.accountType] === AccountType.STAFF &&
          m.accountActivated
        ) {
          staffMembers.push({
            id: m.accountUuid,
            name: `${m.lastName} ${m.firstName}`,
          });
        }
      });
    }

    setStaffMembers(staffMembers);
  }, [members]);

  React.useEffect(() => {
    if (accountId) {
      const account = members?.find(
        (member) => member.accountUuid == accountId
      );
      account && setSelectedAccount(account);
    } else {
      setSelectedAccount(currentUser);
    }
  }, [accountId, members, currentUser]);

  React.useEffect(() => {
    formContactDetails.setFieldsValue(selectedAccount);

    if (selectedAccount?.hasProfilePicture && !selectedAccount.profilePicture)
      dispatch(fetchProfilePictureRequest(selectedAccount?.userUuid));
  }, [selectedAccount]);

  const connectXeroAccount = () => {
    dispatch(fetchConnectUrlRequest(Provider.XERO, 'linkxero'));
  };

  const disconnectXeroAccount = () => {
    dispatch(disconnectProviderRequest(Provider.XERO));
  };

  const disconnectGoogleAccount = () => {
    dispatch(disconnectProviderRequest(Provider.GOOGLE));
  };

  const removeMfa = () => {
    dispatch(disableMfaRequest());
  };

  const deleteProfile = () => {
    dispatch(deleteAccountRequest());
  };

  const updateContact = (user: User) => {
    dispatch(updateDetailsRequest(user));
    dispatch(turnDrawerOff());
  };

  const updateOwner = (accountId: string) => {
    dispatch(changeOwnerRequest(accountId));
    dispatch(turnDrawerOff());
  };

  useEffect(() => {
    dispatch(fetchTimezonesRequest());
    dispatch(fetchQRCodeRequest());
    dispatch(fetchCountriesExternalDataRequest());
    currentUser && dispatch(fetchUserHistoryRequest(currentUser?.userUuid));
    !members && dispatch(fetchUsersRequest());
    if (errorId && errorId === 'linkused') {
      const message: MessageData = {
        title: 'Connection problem',
        description:
          'The Xero account that you are trying to connect to is already connected to another account. Multiple connections not allowed.',
        type: MessageStates.ERROR,
      };
      dispatch(turnMessageOn(message));
      dispatch(push(routes.account.root));
      //window.location.reload();
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Account Profile | ${process.env.PROJECT_NAME}`}</title>
        <meta name="description" content="This a page for account profile" />
      </Helmet>
      {/* {currentUser && ( */}
      <AccountComponent
        loading={loading}
        currentUser={currentUser}
        history={userHistory}
        connectXeroAccount={connectXeroAccount}
        disconnectXeroAccount={disconnectXeroAccount}
        disconnectGoogleAccount={disconnectGoogleAccount}
        openMFAWindow={openMFAWindow}
        removeMfa={removeMfa}
        deleteProfile={deleteProfile}
        savePassword={savePassword}
        openAccessRights={openAccessRights}
        openEditImageWindow={openEditImageWindow}
        selectedAccount={selectedAccount}
        onDeleteUser={deleteUser}
        countries={countries}
        countriesExternalData={countriesExternalData}
        openEditDetails={openEditDetails}
        openChangeOwnerForm={openChangeOwnerForm}
      />
      {/* )} */}
    </React.Fragment>
  );
};
